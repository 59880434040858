<template>
  <div ref="chartEl"></div>
</template>

<script lang="ts" setup>
import { Ref, onBeforeUnmount, ref, toRefs, watchEffect } from "vue";
import * as vega from "vega";
import * as vegaTooltip from "vega-tooltip";

const chartEl: Ref<HTMLDivElement | null> = ref(null);
let vegaView: vega.View;

const props = defineProps<{ spec: vega.Spec }>();
const { spec } = toRefs(props);

const emit = defineEmits<{ select: [value: unknown] }>();

watchEffect(function () {
  if (chartEl.value != null) {
    const tooltipHandler = new vegaTooltip.Handler();
    vegaView = new vega.View(vega.parse(spec.value), {
      renderer: "svg",
      container: chartEl.value,
      hover: true,
    });
    // If you want to offer selection, have your spec include a signal called
    // "selection", defaulting to null and set to the selected value upon the
    // appropriate event. Here we'll set it back to null once handled so that
    // the same item can be selected consecutively.
    if (Object.hasOwn(vegaView.getState().signals, "selection")) {
      vegaView.addSignalListener("selection", function (_, value) {
        if (value !== null) {
          emit("select", value);
          vegaView.signal("selection", null).runAsync();
        }
      });
    }
    vegaView.tooltip(tooltipHandler.call);
    vegaView.runAsync();
  }
});

onBeforeUnmount(() => vegaView?.finalize());
</script>
