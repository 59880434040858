<template>
  <div class="flex flex-row items-center gap-5" :class="{ 'opacity-50': disabled }">
    <label class="relative inline-block h-[14px] w-[22px]">
      <input type="checkbox" v-model="value" :disabled="disabled" class="size-0 opacity-0" />
      <div
        class="track absolute inset-0 cursor-pointer rounded-full transition duration-300"
        :class="{ 'bg-orange': value, 'bg-gray-500 dark:bg-gray-700': !value }"
      >
        <div
          class="knob absolute bottom-4 left-4 size-[6px] rounded-full bg-white transition duration-300 dark:bg-gray-800"
          :class="{ 'translate-x-10': value }"
        ></div>
      </div>
    </label>
    <label
      class="pb-1 text-gray-500"
      :class="{ 'text-base font-bold': size === 'l', 'text-xs': size === 's' }"
    >
      {{ label }}</label
    >
    <Icon
      class="ml-5 opacity-50"
      name="help"
      color="white"
      size="m"
      @click="toggleDescription()"
      v-if="slots.help"
    />
  </div>
  <div class="ml-20 mt-10 text-gray-700 dark:text-gray-200" v-if="showDescription">
    <slot name="help"></slot>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, useSlots, withDefaults } from "vue";
import Icon from "./Icon.vue";

const props = withDefaults(
  defineProps<{
    label?: string;
    size?: "s" | "l";
    modelValue?: boolean;
    disabled?: boolean;
  }>(),
  {
    size: "s",
  }
);
const emit = defineEmits(["update:modelValue"]);

const slots = useSlots();

const showDescription = ref(false);

function toggleDescription() {
  showDescription.value = !showDescription.value;
}

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
</script>
