import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-e3b4519e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "checkbox" }
const _hoisted_2 = { class: "box" }
const _hoisted_3 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          type: "checkbox",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.value) = $event))
        }, null, 512 /* NEED_PATCH */), [
          [_vModelCheckbox, $setup.value]
        ])
      ]),
      _createElementVNode("div", _hoisted_3, _toDisplayString($props.label), 1 /* TEXT */)
    ])
  ]))
}