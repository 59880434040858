<template>
  <div class="problems">
    <MessageBar :mode="problem.level" v-for="(problem, index) of problems" :key="index">
      <template #title>{{ problem.level === "warning" ? "Warning" : "Error" }}</template>
      <template #body
        ><span class="select-text">{{ problem.message }}</span></template
      >
    </MessageBar>
  </div>
</template>

<script lang="ts" setup>
import MessageBar from "@/common/components/MessageBar.vue";
import { asyncResultOr } from "@/common/lib/async";
import { useExploreStore } from "@/reader/stores/explore";
import { computed } from "vue";

const exploreStore = useExploreStore();
const problems = computed(() => asyncResultOr(exploreStore.problems, []));
</script>
