// Really quick cookie management functions.

export function getCookie(name: string): string | undefined {
  const value = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${name}=`))
    ?.split("=")[1];
  return value;
}

export function setCookie(name: string, value: string, maxAgeSeconds?: number) {
  let cookie = `${name}=${value};max-age=60;Path=/;SameSite=Strict`;
  if (maxAgeSeconds !== undefined) {
    cookie = `${cookie};max-age=${maxAgeSeconds}`;
  }
  document.cookie = cookie;
}
