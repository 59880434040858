<template>
  <div class="text-lg font-bold uppercase">Add Calculation</div>
  <div class="m-10 w-[400px]">
    <DerivedPropertyDef v-model="derivedTerm" />
  </div>
  <div class="p-10 font-medium">{{ description }}</div>
  <div class="text-right">
    <TextButton
      label="Cancel"
      @click.stop="exploreStore.creatingCalculation = undefined"
      :secondary="true"
    />
    <TextButton label="Add" @click.stop="commit" :disabled="isInvalid" />
  </div>
</template>

<script lang="ts" setup>
import { DerivedPropertyTerm, propertyName } from "@/common/lib/derived";
import TextButton from "@/common/components/TextButton.vue";
import { buildNeighborhoodPath } from "@/reader/lib/explore";
import { useExploreStore } from "@/reader/stores/explore";
import { v4 as uuidv4 } from "uuid";
import { computed, Ref, ref } from "vue";
import DerivedPropertyDef from "./DerivedPropertyDef.vue";

const exploreStore = useExploreStore();

const derivedTerm: Ref<DerivedPropertyTerm | null> = ref(null);

const isInvalid = computed(() => derivedTerm.value == null);

const description = computed(function () {
  if (derivedTerm.value == null) return "Please complete all fields above.";
  return "Property Name: " + propertyName(derivedTerm.value);
});

function commit() {
  const term = derivedTerm.value;
  if (term == null) return;
  exploreStore.addColumn({
    alias: uuidv4(),
    property_type: term,
    neighborhood: buildNeighborhoodPath(exploreStore.creatingCalculation!),
  });
  exploreStore.creatingCalculation = undefined;
}
</script>
