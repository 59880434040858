import { createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mx-5 basis-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$setup.exploreStore.hideUnusedProperties || $setup.isCurrentColumn)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["mx-5 my-4 flex h-20 cursor-pointer items-center rounded-full p-4 hover:bg-gray-200 dark:hover:bg-black", { 'dark:text-white': $setup.isCurrentColumn, 'text-gray-500': !$setup.isCurrentColumn }])
        }, [
          _createVNode($setup["IconButton"], {
            name: $setup.isCurrentColumn ? 'checkbox-selected' : 'checkbox-empty',
            highlighted: $setup.isCurrentColumn,
            color: $setup.isCurrentColumn ? 'orange' : undefined,
            disabled: $setup.nonaggregateColumnsProhibited,
            tooltip: 
        $setup.nonaggregateColumnsProhibited
          ? 'While Summarizing All, only aggregate columns can be added.'
          : undefined
      ,
            size: "l",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.toggleBasicColumn()))
          }, null, 8 /* PROPS */, ["name", "highlighted", "color", "disabled", "tooltip"]),
          _createVNode($setup["Dropdown"], {
            placement: "right",
            class: "flex basis-full items-center"
          }, {
            popper: _withCtx(() => [
              _withDirectives(_createVNode($setup["Menu"], { items: $setup.menuItems }, null, 8 /* PROPS */, ["items"]), [
                [_directive_close_popper]
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString($setup.label), 1 /* TEXT */),
              _createVNode($setup["IconButton"], {
                name: "chevron-down",
                size: "xs"
              })
            ]),
            _: 1 /* STABLE */
          })
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.derivedAliases, (alias) => {
      return (_openBlock(), _createBlock($setup["TreeDerivedColumn"], {
        alias: alias,
        key: alias
      }, null, 8 /* PROPS */, ["alias"]))
    }), 128 /* KEYED_FRAGMENT */)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.relatedFilters, (filter) => {
      return (_openBlock(), _createBlock($setup["Filter"], {
        filter: filter,
        key: filter.alias
      }, null, 8 /* PROPS */, ["filter"]))
    }), 128 /* KEYED_FRAGMENT */))
  ], 64 /* STABLE_FRAGMENT */))
}