<template>
  <div class="absolute left-0 right-0 top-0 z-0 h-[24px] bg-gray-200 dark:bg-gray-800"></div>
  <div class="absolute inset-0 z-0 overflow-auto" data-test="table">
    <table class="z-10 border-collapse">
      <tr>
        <th
          v-for="colDetails in columns"
          :key="colDetails.alias"
          class="sticky top-0 z-10 bg-gray-200 p-0 text-center align-baseline"
        >
          <div
            class="flex h-[25px] items-center justify-between whitespace-nowrap border-b border-r border-white px-10 dark:border-gray-900"
            :class="{ 'justify-end text-right': colDetails.isQuantitative }"
          >
            <div class="mx-auto">{{ colDetails.label }}</div>
          </div>
        </th>
      </tr>
      <SubtableComponent :subtable="table" :columns="columns" :depth="0" />
    </table>
  </div>
</template>

<script lang="ts" setup>
import { FetchNPropertySet, findPropertyDefByAlias } from "@/common/lib/query";
import { TableVisualization, TableVisualizationGroup } from "@/reader/lib/visualization";
import { computed, toRefs } from "vue";
import { fromPairs, last, tail } from "lodash";
import { stringifyValueOrCompositeValue } from "@/common/lib/graph";
import { GraphValue, toValue } from "@/common/lib/value";
import { propertyName } from "@/common/lib/derived";
import SubtableComponent from "./table/Subtable.vue";

const props = defineProps<{
  visualization: TableVisualization;
  results: FetchNPropertySet[];
  width: number;
  height: number;
}>();

defineEmits<{ select: [alias: string, value: GraphValue | null] }>();

const { visualization, results } = toRefs(props);

export interface Subtable {
  rows?: Record<string, GraphValue[]>[];
  groups?: TableGroup[];
}

export interface Column {
  alias: string;
  label: string;
  isQuantitative: boolean;
}

interface TableGroup {
  id: string;
  name: GraphValue;
  contents: Subtable;
}

const columns = computed((): Column[] =>
  visualization.value.config.columns.map(function (colspec) {
    const propDef = findPropertyDefByAlias(visualization.value.query, colspec.alias)!;
    return {
      alias: colspec.alias,
      label: propertyName(propDef, undefined, colspec.label),
      isQuantitative: false,
    };
  })
);

function buildRows(results: FetchNPropertySet[]) {
  return results.map(function (row) {
    return fromPairs(
      visualization.value.config.columns.map(function (col) {
        return [col.alias, row[col.alias].map((v) => toValue(stringifyValueOrCompositeValue(v)))];
      })
    );
  });
}

function buildGroups(
  results: FetchNPropertySet[],
  groupspecs: TableVisualizationGroup[]
): TableGroup[] {
  const groupspec = groupspecs[0];
  const rowGroups: FetchNPropertySet[][] = [];
  let currentId: string | undefined = undefined;
  for (const row of results) {
    const id = stringifyValueOrCompositeValue(row[groupspec.category][0]);
    if (id !== currentId) {
      currentId = id;
      rowGroups.push([]);
    }
    last(rowGroups)!.push(row);
  }
  return rowGroups.map((rowGroup) => ({
    id: stringifyValueOrCompositeValue(rowGroup[0][groupspec.category][0]),
    name: rowGroup[0][groupspec.category_name ?? groupspec.category][0] as GraphValue,
    contents: buildSubtable(rowGroup, tail(groupspecs)),
  }));
}

function buildSubtable(
  results: FetchNPropertySet[],
  groupspecs: TableVisualizationGroup[]
): Subtable {
  if (groupspecs.length == 0) {
    return { rows: buildRows(results) };
  } else {
    return { groups: buildGroups(results, groupspecs) };
  }
}

const table = computed(() => buildSubtable(results.value, visualization.value.config.groups ?? []));
</script>
