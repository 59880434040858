import { httpClient as axios } from "@/common/http/http";
import {
  Async,
  asyncFailed,
  asyncInProgress,
  asyncNotStarted,
  asyncSucceeded,
} from "@/common/lib/async";
import { isEqual, remove, without } from "lodash";
import { defineStore } from "pinia";
import { Catalog, Table } from "./catalog";

// This should perhaps move once it is better understood
export interface SystemTable {
  catalog: string;
  schema: string;
  // group is catalog:schema
  group: string;
  name: string;
  table: Table;
}

interface SystemState {
  catalogs: Async<Catalog[]>;
  collapsedGroups: string[];
}

export interface SourceSelection {
  system: string;
  table: SystemTable;
}

interface State {
  systems: Map<string, SystemState>;
  search: string;
  selection: SourceSelection[];
}

export const useSourceBrowserStore = defineStore("editor-sourceBrowser", {
  state: (): State => ({
    systems: new Map([["dbx", { catalogs: asyncNotStarted(), collapsedGroups: [] }]]),
    search: "",
    selection: [],
  }),
  getters: {
    system(state) {
      return (system: string) => state.systems.get(system) as SystemState;
    },
    isSelected(state) {
      return (system: string, table: SystemTable) =>
        state.selection.find((sel) => isEqual(sel, { system, table }));
    },
  },
  actions: {
    toggleGroupCollapsed(system: string, group: string) {
      const systemState = this.system(system);
      if (systemState.collapsedGroups.includes(group)) {
        systemState.collapsedGroups = without(systemState.collapsedGroups, group);
      } else {
        systemState.collapsedGroups.push(group);
      }
    },
    toggleSelected(system: string, table: SystemTable) {
      if (this.isSelected(system, table)) {
        remove(this.selection, (sel) => isEqual(sel, { system, table }));
      } else {
        this.selection.push({ system, table });
      }
    },
    clearSelected() {
      this.selection = [];
    },
    async loadSystemTables(system: string, forceRefresh = false) {
      const systemState = this.system(system);
      systemState.catalogs = asyncInProgress();
      let response;
      try {
        let url = "/api/dbx/catalogs";
        if (forceRefresh) {
          url = url + "?refresh=true";
        }
        response = await axios.get(url);
      } catch (error) {
        systemState.catalogs = asyncFailed("Can't load system contents");
        return;
      }
      systemState.catalogs = asyncSucceeded(response.data.tables);
    },
    async clearTableCaches(table: { catalog: string; schema: string; table: string }) {
      await axios.post(
        `/api/dbx/catalogs/${table.catalog}/schemas/${table.schema}/tables/${table.table}/clear-caches`
      );
    },
    async calculateStatistics(
      table: { catalog: string; schema: string; table: string },
      workspaceId: string
    ) {
      const queryOptions = `?workspace_id=${workspaceId}&force_refresh=true&fetch_if_needed=true`;
      await axios.get(
        `/api/dbx/catalogs/${table.catalog}/schemas/${table.schema}/tables/${table.table}/statistics${queryOptions}`
      );
    },
  },
});
