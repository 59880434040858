import { httpClient } from "@/common/http/http";
import { useSourceBrowserStore } from "@/common/stores/sourceBrowser";
import { AxiosResponse } from "axios";
import { isEmpty } from "lodash";
import { defineStore } from "pinia";

interface State {
  loaded: boolean;
  connected?: boolean;
  warehouses: Warehouse[];
  currentWarehouse?: Warehouse;
  workspaces?: Workspace[];
  currentWorkspaceHost?: string;
  currentWorkspace?: Workspace;
  editInProgress: boolean;
  initialized: boolean;
}

interface DatabricksStatus {
  connected: boolean;
  warehouse?: Warehouse;
}

export interface Workspace {
  host: string;
  nickname: string;
  personal_access_token: string;
  sql_http_path: string;
}

export interface Warehouse {
  id: string;
  name: string;
  sql_path: string;
  cluster_size: string;
  creator_name: string;
  state: "DELETED" | "DELETING" | "RUNNING" | "STARTING" | "STOPPED" | "STOPPING";
  enable_photon: boolean;
  enable_serverless_compute: boolean;
}

interface ListWarehousesResponse {
  warehouses: Warehouse[];
}

export const useDatabricksStore = defineStore("databricks", {
  state: (): State => ({
    loaded: false,
    connected: undefined,
    warehouses: [],
    currentWarehouse: undefined,
    workspaces: undefined,
    currentWorkspaceHost: undefined,
    currentWorkspace: undefined,
    editInProgress: false,
    initialized: false,
  }),
  actions: {
    cancel() {
      const connected = this.connected;
      this.$reset();
      this.connected = connected;
    },
    initializeWorkspaceState() {
      if (!this.initialized) {
        this.initialized = true;
        this.reloadWorkspaceState();
      }
    },
    async reloadWorkspaceState() {
      await Promise.all([this.loadWorkspace(), this.listWorkspaces(), this.listWarehouses()]);
      this.initialized = true;
    },
    async save() {
      if (this.currentWorkspace === undefined) {
        return;
      }
      await this.saveWorkspace(this.currentWorkspace);
    },
    async getStatus(): Promise<DatabricksStatus> {
      const response: AxiosResponse<DatabricksStatus> = await httpClient.get("/api/dbx/status");
      return response.data;
    },
    async checkConnected(): Promise<boolean> {
      const status = await this.getStatus();
      this.connected = status.connected;
      return this.connected;
    },
    async loadWorkspace() {
      const response = await httpClient.get("/api/dbx/current-workspace");
      this.currentWorkspace = response.data;
      if (isEmpty(this.currentWorkspace)) {
        this.editInProgress = true;
        this.currentWorkspace = {
          nickname: "",
          host: "",
          sql_http_path: "",
          personal_access_token: "",
        };
        return;
      }
      this.currentWorkspaceHost = this.currentWorkspace.host;
      this.loaded = true;
    },
    async listWorkspaces() {
      const response = await httpClient.get("/api/dbx/workspaces");
      this.workspaces = response.data.workspaces || [];
    },
    async saveWorkspace(config: Workspace) {
      await httpClient.put("/api/dbx/workspaces", config);
      this.reloadWorkspaceState();
    },
    async deleteCurrentWorkspace() {
      const host = this.currentWorkspaceHost;
      if (host === undefined) {
        return;
      }
      await httpClient.delete(`/api/dbx/workspaces/${host}`);
      this.reloadWorkspaceState();
    },
    addWorkspace() {
      const first = this.workspaces === undefined || this.workspaces.length === 0;
      this.currentWorkspace = {
        host: "",
        nickname: "",
        personal_access_token: "",
        sql_http_path: "",
      };
      if (first) {
        this.currentWorkspace.nickname = "Default workspace";
      }
      this.editInProgress = true;
      this.warehouses = [];
    },
    async selectWorkspace(workspaceHostName: string) {
      if (!workspaceHostName) {
        return;
      }
      if (this.currentWorkspace?.host === workspaceHostName) {
        return;
      }
      const sourceBrowserStore = useSourceBrowserStore();
      await httpClient.post(`/api/dbx/current-workspace/${workspaceHostName}`);
      // Reload
      this.reloadWorkspaceState();
      sourceBrowserStore.loadSystemTables("dbx", true);
    },
    async cancelEditWorkspace() {
      this.editInProgress = false;
      await this.reloadWorkspaceState();
    },
    // Warehouse API
    async getCurrentWarehouse(): Promise<Warehouse | undefined> {
      const status = await this.getStatus();
      this.currentWarehouse = status.warehouse;
      return this.currentWarehouse;
    },
    async listWarehouses(): Promise<Warehouse[]> {
      const response = await httpClient.get("/api/dbx/warehouses");
      const config: ListWarehousesResponse = response.data;
      this.warehouses = config.warehouses;
      return this.warehouses;
    },
    async listWarehousesForCreds(creds: Workspace): Promise<Warehouse[]> {
      const response = await httpClient.post("/api/dbx/list-warehouses-for-token", creds);
      const body: ListWarehousesResponse = response.data;
      return body.warehouses;
    },
  },
});
