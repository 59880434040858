<template>
  <div class="flex flex-row justify-around">
    <div
      class="group flex h-[35px] max-w-4xl flex-none basis-full items-center rounded-full border border-gray-700 px-10 text-xl"
      :class="{
        'bg-white dark:bg-gray-900': inProgress || succeeded,
        'focus-within:border-orange focus-within:bg-white dark:focus-within:bg-gray-900':
          !inProgress,
      }"
    >
      <Icon name="ct-chat" size="xl" color="orange" />
      <input
        ref="askBar"
        class="basis-full border-none bg-transparent p-5 outline-none"
        placeholder="Ask a question about your data"
        v-model="inputText"
        :disabled="inProgress"
        @keyup.enter="submitQuestion"
        @focus="prepareForNewQuestion"
        data-test="explore-llm-textbox"
      />
      <div
        class="hidden whitespace-nowrap text-base group-focus-within:block"
        v-if="hasInput && !inProgress"
      >
        Press <span class="rounded-sm border border-gray-500 px-[4px] py-2">Enter</span> to query
      </div>
      <div class="relative top-[-3px]" v-if="inProgress">
        <Spinner />
      </div>
      <Icon v-if="succeeded" name="check" size="xl" color="orange" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Icon from "@/common/components/Icon.vue";
import Spinner from "@/common/components/Spinner.vue";
import { useKeyHandler } from "@/common/composables/useKeyHandler";
import { AsyncStatus, asyncNotStarted } from "@/common/lib/async";
import { useExploreStore } from "@/reader/stores/explore";
import { trim } from "lodash";
import { computed, ref } from "vue";
import { KeyCommand } from "@/common/lib/modifierKeys";
import { environment } from "@/common/environments/environmentLoader";

const inputText = ref("");
const askBar = ref<HTMLTextAreaElement | undefined>(undefined);
const askBarKeyCommand = KeyCommand.readKeyCommand(environment.require("KEY_COMMAND_ASK_BAR"));
useKeyHandler().registerHandler(askBarKeyCommand, () => askBar.value?.select(), true, false);

const exploreStore = useExploreStore();

const sanitizedInput = computed(() => trim(inputText.value));
const hasInput = computed(() => sanitizedInput.value.length > 0);
const inProgress = computed(() => exploreStore.askResponse.status === AsyncStatus.InProgress);
const succeeded = computed(() => exploreStore.askResponse.status === AsyncStatus.Succeeded);

function submitQuestion() {
  exploreStore.askQuestion(sanitizedInput.value);
}

function prepareForNewQuestion() {
  if (exploreStore.askResponse.status != AsyncStatus.NotStarted) {
    askBar.value?.select();
    exploreStore.askResponse = asyncNotStarted();
  }
}
</script>
