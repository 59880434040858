import { propertyName, propertyValueType } from "@/common/lib/derived";
import { formatValue, noOpFormatValue, ValueWithFormattedValue } from "@/common/lib/format";
import { GraphCompoundValue } from "@/common/lib/graph";
import { COMPOSITE_PROPERTY_VALUE_TYPE } from "@/common/lib/knowledge";
import { Query, QueryPathNode } from "@/common/lib/query";
import { GraphValue, GraphValueType, isValue, toValue } from "@/common/lib/value";
import { isEqual, isString, mapValues } from "lodash";
import { UseQueryResult } from "../composables/useQuery";
import { readerConceptTitle } from "./concept";

export enum VisualizationType {
  DiscreteDistribution = "discrete_distribution",
  TimeDistribution = "time_distribution",
  Indicator = "indicator",
  PieChart = "pie_chart",
  Table = "table",
  Sankey = "sankey",
  FinancialStatement = "financial_statement",
}

interface BaseVisualization {
  type: VisualizationType;
  title: string; // Maybe this is eventually optional
  query: Query;
  config: Record<string, unknown>;
}

export interface DiscreteDistributionVisualization extends BaseVisualization {
  type: VisualizationType.DiscreteDistribution;
  config: {
    category: string;
    category_name?: ValueGenerator; // If not specified, category is used
    value: ValueGenerator;
  };
}

export interface TimeDistributionVisualization extends BaseVisualization {
  type: VisualizationType.TimeDistribution;
  config: {
    time: string;
    value: string;
  };
}

export interface PieChartVisualization extends BaseVisualization {
  type: VisualizationType.PieChart;
  config: {
    category: string;
    category_name?: ValueGenerator; // If not specified, category is used
    value: ValueGenerator;
  };
}

export interface IndicatorVisualization extends BaseVisualization {
  type: VisualizationType.Indicator;
  config: {
    value: ValueGenerator;
  };
}

export interface TableVisualizationGroup {
  category: string;
  category_name?: ValueGenerator; // If not specified, category is used
}

export interface TableVisualization extends BaseVisualization {
  type: VisualizationType.Table;
  config: {
    columns: Array<{
      value: ValueGenerator;
      label?: string; // If left out, we'll try to determine one automatically
    }>;
    groups?: TableVisualizationGroup[];
  };
}

export interface FinancialStatementRow {
  label: string;
  id?: string; // Give this row an ID so as to refer to it in a compareToId
  compareToId?: string;
  value: ValueGenerator;
  highlight?: boolean;
  contents?: FinancialStatementRow[];
}

export interface FinancialStatementVisualization extends BaseVisualization {
  type: VisualizationType.FinancialStatement;
  config: {
    rows: FinancialStatementRow[];
    columns: ValueGenerator;
  };
}

export interface SankeyVisualization extends BaseVisualization {
  type: VisualizationType.Sankey;
  config: {
    links: Array<{
      from: string;
      to: string;
      value: ValueGenerator;
    }>;
    transformer?: string;
  };
}

export type Visualization =
  | DiscreteDistributionVisualization
  | TimeDistributionVisualization
  | IndicatorVisualization
  | PieChartVisualization
  | TableVisualization
  | SankeyVisualization;

export enum ValueGeneratorType {
  Property = "property",
  Title = "title",
}

interface BaseValueGenerator {
  type: ValueGeneratorType;
}

interface PropertyValueGenerator extends BaseValueGenerator {
  type: ValueGeneratorType.Property;
  alias: string;
  transformer?: string; // Temporary - hold your nose and take a look at format.ts' TRANSFORMERS
}

interface TitleValueGenerator extends BaseValueGenerator {
  type: ValueGeneratorType.Title;
  path?: QueryPathNode[]; // If not specified, root concept is used
}

type NormalizedValueGenerator = PropertyValueGenerator | TitleValueGenerator;

// If you specify a string, it should be an alias (and you get a PropertyValueGenerator)
export type ValueGenerator = NormalizedValueGenerator | string;

export function generateValue(
  generator: ValueGenerator,
  result: UseQueryResult,
  query: Query
): ValueWithFormattedValue | null {
  generator = normalizeGenerator(generator);
  switch (generator.type) {
    case ValueGeneratorType.Property: {
      const value = oversimplifyValues(result.valuesByAlias[generator.alias] ?? []);
      const propType = query.columns.find((c) => c.alias === generator.alias)?.property_type;
      if (value == null || propType == null) return null;
      return formatValue(propType, value, generator.transformer);
    }
    case ValueGeneratorType.Title: {
      const concept =
        generator.path != null
          ? result.conceptsByPath.find((cbp) => isEqual(cbp.path, generator.path))?.concepts[0]
          : result.root;
      if (concept == null) return null;
      const title = readerConceptTitle(concept);
      if (title == null) return null;
      return noOpFormatValue(toValue(title));
    }
  }
}

export function generateValues(
  generators: Record<string, ValueGenerator>,
  result: UseQueryResult,
  query: Query
) {
  return mapValues(generators, (generator) => generateValue(generator, result, query));
}

// Gives the value type that a generator will return
export function generatorOutputType(
  generator: ValueGenerator,
  query: Query
): GraphValueType | null {
  generator = normalizeGenerator(generator);
  switch (generator.type) {
    case ValueGeneratorType.Property: {
      const propDef = query.columns.find((c) => c.alias === generator.alias)?.property_type;
      if (propDef == null) return null;
      const valueType = propertyValueType(propDef);
      if (valueType === COMPOSITE_PROPERTY_VALUE_TYPE) return null;
      return valueType;
    }
    case ValueGeneratorType.Title:
      return GraphValueType.String;
  }
}

// Tries to come up with a reasonable label for a generator's output
export function generatorName(generator: ValueGenerator, query: Query): string {
  generator = normalizeGenerator(generator);
  switch (generator.type) {
    case ValueGeneratorType.Property: {
      const propDef = query.columns.find((c) => c.alias === generator.alias)?.property_type;
      if (propDef == null) return "?";
      return propertyName(propDef, undefined); // TODO Probably include concept
    }
    case ValueGeneratorType.Title:
      return "Title"; // TODO Use concept name
  }
}

function normalizeGenerator(generator: ValueGenerator): NormalizedValueGenerator {
  if (isString(generator)) {
    return { type: ValueGeneratorType.Property, alias: generator };
  } else {
    return generator;
  }
}

export function oversimplifyValues(values: (GraphValue | GraphCompoundValue)[]) {
  if (values.length != 1) return null;
  if (!isValue(values[0])) return null; // Conveniently ignoring compound values for now
  return values[0] as GraphValue;
}

// Hardcoded for now, but we know this is going to change at runtime
// These ought to match a color in tailwind.config.js
export function visualizationTheme(darkMode: boolean) {
  return {
    datum: "#F8915B",
    selectedDatum: "#F75E0E",
    label: darkMode ? "#FFFFFF" : "#191919",
    axis: darkMode ? "#FFFFFF" : "#191919",
    brush: "#DDDDDD",
  };
}
