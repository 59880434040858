<template>
  <Chart :spec="spec" @select="handleSelect" />
</template>

<script lang="ts" setup>
import { FetchNPropertySet, findPropertyDefByAlias } from "@/common/lib/query";
import { singleValuesOrNull, TimeDistributionVisualization } from "@/reader/lib/visualization";
import { ComputedRef, Ref, computed, inject, toRefs } from "vue";
import * as vega from "vega";
import Chart from "@/common/components/Chart.vue";
import { DarkMode } from "@/common/lib/keys";
import { GraphValue, GraphValueType } from "@/common/lib/value";
import { DateTime } from "luxon";
import { isString } from "lodash";
import { PropertyOpType } from "@/common/lib/derived";

const props = defineProps<{
  visualization: TimeDistributionVisualization;
  results: FetchNPropertySet[];
  width?: number;
  height?: number;
}>();
const { visualization, results, width, height } = toRefs(props);

const emit = defineEmits<{
  select: [alias: string, value: [GraphValue, GraphValue]];
}>();

const darkMode = inject(DarkMode) as Ref<boolean>;

interface Datum {
  time: Date;
  value: number;
  tooltip: Record<string, string>;
}

const data = computed(() =>
  results.value.map(function (row): Datum {
    const config = visualization.value.config;
    const values = singleValuesOrNull(row);
    const time = DateTime.fromISO(values[config.time]!.value as string);
    const value = values[config.value]!.value;
    return {
      time: time.toJSDate(),
      value: Number(value),
      tooltip: { [time.toLocaleString(DateTime.DATE_SHORT)]: String(value) },
    };
  })
);

// Sets vega's time unit by peeking at the property def for our time buckets
// This determines the width of each time bucket bar
const timeUnit = computed(function () {
  const prop = findPropertyDefByAlias(visualization.value.query, visualization.value.config.time);
  if (prop == null || isString(prop) || prop.op !== PropertyOpType.DateTrunc) return "day";
  return (
    {
      YEAR: "year",
      QUARTER: "quarter",
      MONTH: "month",
      WEEK: "week",
      DAY: "day",
      HOUR: "hours", // note that some of these are plural
      MINUTE: "minutes",
      SECOND: "seconds",
      MILLISECOND: "milliseconds",
    }[prop.bucket_size.toUpperCase()] ?? "day"
  );
});

const spec: ComputedRef<vega.Spec> = computed(function () {
  const spec: vega.Spec = {
    width: (width.value ?? 370) - 10,
    height: (height.value ?? 400) - 10,
    padding: 5,
    autosize: "fit",
    data: [
      {
        name: "table",
        values: data.value,
      },
    ],
    signals: [
      {
        name: "brush",
        value: 0,
        on: [
          {
            events: "pointerdown",
            update: "[x(), x()]",
          },
          {
            events: "[pointerdown, window:pointerup] > window:pointermove!",
            update: "[brush[0], clamp(x(), 0, width)]",
          },
          {
            events: { signal: "delta" },
            update: "clampRange([anchor[0] + delta, anchor[1] + delta], 0, width)",
          },
        ],
      },
      {
        name: "anchor",
        value: null,
        on: [{ events: "@brush:pointerdown", update: "slice(brush)" }],
      },
      {
        name: "xdown",
        value: 0,
        on: [{ events: "@brush:pointerdown", update: "x()" }],
      },
      {
        name: "delta",
        value: 0,
        on: [
          {
            events: "[@brush:pointerdown, window:pointerup] > window:pointermove!",
            update: "x() - xdown",
          },
        ],
      },
      {
        name: "selection",
        value: null,
        on: [{ events: "pointerup", update: "[invert('x', brush[0]), invert('x', brush[1])]" }],
      },
    ],
    scales: [
      {
        name: "x",
        type: "time",
        range: "width",
        domain: { data: "table", field: "time" },
        domainMax: {
          signal: `utcOffset('${timeUnit.value}', extent(pluck(data('table'), 'time'))[1], 1)`,
        },
      },
      {
        name: "y",
        type: "linear",
        range: "height",
        domain: { data: "table", field: "value" },
        nice: true,
        zero: true,
      },
    ],
    axes: [
      {
        orient: "bottom",
        scale: "x",
        domain: false,
        ticks: false,
        labelPadding: 5,
        labelColor: darkMode.value ? "white" : "black",
      },
      {
        scale: "y",
        orient: "left",
        labelColor: darkMode.value ? "white" : "black",
        labelLimit: 120,
        labelPadding: 5,
      },
    ],
    marks: [
      {
        type: "rect",
        from: { data: "table" },
        encode: {
          update: {
            x: { scale: "x", field: "time" },
            y: { scale: "y", field: "value" },
            y2: { scale: "y", value: 0 },
            x2: { scale: "x", signal: `utcOffset('${timeUnit.value}', datum.time, 1)` },
            fill: { value: "#f75e0e" },
            tooltip: { signal: "datum.tooltip" },
          },
          hover: {
            fill: { value: "#F9AA81" },
          },
        },
      },
      {
        type: "rect",
        name: "brush",
        encode: {
          enter: {
            y: { signal: "range('y')[0]" },
            y2: { signal: "range('y')[1]" },
            fill: { value: "#f75e0e" },
            fillOpacity: { value: 0.3 },
          },
          update: {
            x: { signal: "brush[0]" },
            x2: { signal: "brush[1]" },
          },
        },
      },
    ],
  };
  return spec;
});

function handleSelect(selected: unknown) {
  const range = selected as [Date, Date];
  const dateTimeRange = [DateTime.fromJSDate(range[0]), DateTime.fromJSDate(range[1])];
  if (dateTimeRange[0].equals(dateTimeRange[1])) return;
  if (dateTimeRange[0].toMillis() > dateTimeRange[1].toMillis()) dateTimeRange.reverse();
  emit("select", visualization.value.config.time, [
    { _type: GraphValueType.Date, value: dateTimeRange[0].toISODate()! },
    { _type: GraphValueType.Date, value: dateTimeRange[1].toISODate()! },
  ]);
}
</script>
