<template>
  <div class="absolute inset-0 flex items-center justify-center">
    <div v-if="datum != null" class="text-[50px]">
      <GraphValueComponent :value="datum" />
    </div>
    <div v-else>&mdash;</div>
  </div>
</template>

<script lang="ts" setup>
import { FetchNPropertySet } from "@/common/lib/query";
import { GraphValue, isValue } from "@/common/lib/value";
import { IndicatorVisualization } from "@/reader/lib/visualization";
import { computed, toRefs } from "vue";
import GraphValueComponent from "@/common/components/GraphValue.vue";

const props = defineProps<{
  visualization: IndicatorVisualization;
  results: FetchNPropertySet[];
}>();
const { visualization, results } = toRefs(props);

const datum = computed(function () {
  const value = results.value[0]?.[visualization.value.config.value]?.[0];
  if (value == null || !isValue(value)) return null;
  return value as GraphValue;
});
</script>
