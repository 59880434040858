import { AvailableModule } from "@/common/stores/knowledgeStore";
import { isEmpty } from "lodash";
import { DateTime } from "luxon";
import { GraphValueType } from "./value";

export const BASE_CONCEPT_TYPE: ConceptKnowledgeRef = "concept._.thing";
export const BASE_PROPERTY_TYPE: PropertyKnowledgeRef = "property._.descriptor";

export const RELATED_LINK_TYPE: LinkKnowledgeRef = "link._.related_to";
export const ROLE_LINK_TYPE: LinkKnowledgeRef = "link._.as_a";

export const COMPOSITE_PROPERTY_VALUE_TYPE = "composite";

export const RECORD_CONCEPT_TYPE: ConceptKnowledgeRef = "concept._.record";

export const PROPERTY_VALUE_TYPES = [
  ...Object.values(GraphValueType),
  COMPOSITE_PROPERTY_VALUE_TYPE,
];

// Each core value type has a corresponding property type that's intended to
// be the parent of other properties with that value_type
export const VALUE_TYPE_BASE_PROPERTY_TYPES: Record<GraphValueType, PropertyKnowledgeRef> = {
  [GraphValueType.Bool]: "property._.bool",
  [GraphValueType.Date]: "property.cal.date",
  [GraphValueType.Datetime]: "property.cal.datetime",
  [GraphValueType.Duration]: "property.cal.duration",
  [GraphValueType.Float]: "property._.float",
  [GraphValueType.Geopoint]: "property.geo.geopoint",
  [GraphValueType.Integer]: "property._.integer",
  [GraphValueType.String]: "property._.text",
  [GraphValueType.Time]: "property.cal.time",
  [GraphValueType.Bytes]: BASE_PROPERTY_TYPE, // This is a temporary cop-out
};

export enum KnowledgeType {
  Property = "property",
  Concept = "concept",
  Link = "link",
  Logic = "logic",
  Enrichment = "enrichment",
  Parser = "parser",
  Generator = "generator",
  Shape = "shape",
}

interface BaseKnowledgeItem {
  type: KnowledgeType;
  name: string;
  label: string;
  group: string;
  parent?: string;
  description?: string;
  module_name?: string;
  extra?: Record<string, unknown>;
  deprecated?: boolean;

  // Is this ad hoc / local-only knowledge? (view only)
  adhoc?: boolean;
}

export interface PropertyComponentType {
  value_type: GraphValueType;
  label: string;
}

export interface PropertyType extends BaseKnowledgeItem {
  type: KnowledgeType.Property;
  components?: Record<string, PropertyComponentType>;
  value_type?: GraphValueType | typeof COMPOSITE_PROPERTY_VALUE_TYPE;
  constructor?: string;
  aliases?: string[];

  automerge: boolean;
  disincentivize_multiplicity: boolean;
  logic?: {
    parsers?: {
      name: string;
    }[];
  };
}

export interface ConceptType extends BaseKnowledgeItem {
  type: KnowledgeType.Concept;
  properties: string[];
  title_properties: string[];
  collect_properties: string[];
  aliases?: string[];
  indicative: string[];
  icon_name: string;
}

export interface LinkType extends BaseKnowledgeItem {
  type: KnowledgeType.Link;
  reverse_label?: string;
}

export interface LogicType extends BaseKnowledgeItem {
  type: KnowledgeType.Logic;
}

export interface EnrichmentType extends BaseKnowledgeItem {
  type: KnowledgeType.Enrichment;
  signatures: Record<string, EnrichmentSignature>;
  logic: string;
}

export interface ParserType extends BaseKnowledgeItem {
  type: KnowledgeType.Parser;
  property_type: string;
  params: Record<string, ParserParam>;
}

export enum ShapeComponent {
  Actor = "actor",
  Role = "role",
  Subject = "subject",
}

export interface ShapeType extends BaseKnowledgeItem {
  type: KnowledgeType.Shape;
  reverse_label: string;
  [ShapeComponent.Actor]: ConceptKnowledgeRef;
  [ShapeComponent.Role]: ConceptKnowledgeRef;
  [ShapeComponent.Subject]: ConceptKnowledgeRef;
  implicitly_implied: boolean;
  aliases?: string[];
}

export interface GeneratorType extends BaseKnowledgeItem {
  type: KnowledgeType.Generator;
  property_type: string;
  outputs: Record<string, GeneratorOutput>;
}

export interface EnrichmentSignature {
  concepts: Record<string, EnrichmentSignatureConcept>;
  links: Record<string, EnrichmentSignatureLink>;
  properties: Record<string, EnrichmentSignatureProperty>;
}

export interface ParserParam {
  value_type: GraphValueType;
  label: string;
  description?: string;
  aliases?: string[];
  required: boolean;
}

export interface GeneratorOutput {
  value_type: GraphValueType;
  label: string;
}

export enum EnrichmentSignatureRole {
  Root = "root",
  Input = "input",
  Output = "output",
  Both = "both",
}

export interface EnrichmentSignatureElement {
  role: EnrichmentSignatureRole;
  type: string;
}

type EnrichmentSignatureConcept = EnrichmentSignatureElement;

export interface EnrichmentSignatureProperty extends EnrichmentSignatureElement {
  on_concept: string;
  required: boolean;
}

export interface EnrichmentSignatureLink extends EnrichmentSignatureElement {
  from_concept: string;
  to_concept: string;
}

export type KnowledgeItem =
  | ConceptType
  | PropertyType
  | LinkType
  | LogicType
  | EnrichmentType
  | ParserType
  | GeneratorType
  | ShapeType;

// Type references (strings that describe a type)
type BaseKnowledgeRef<T extends KnowledgeType> = `${T}.${string}.${string}`;
export type KnowledgeRef = BaseKnowledgeRef<KnowledgeType>; // Reference to knowledge of any type

// For convenience - add more as needed
export type PropertyKnowledgeRef = BaseKnowledgeRef<KnowledgeType.Property>;
export type ConceptKnowledgeRef = BaseKnowledgeRef<KnowledgeType.Concept>;
export type LinkKnowledgeRef = BaseKnowledgeRef<KnowledgeType.Link>;

export function knowledgeItemHasId(item: KnowledgeItem, id: string): boolean {
  const idParts = splitKnowledgeId(id);
  return item.type == idParts.type && item.group == idParts.group && item.name == idParts.name;
}

export function splitKnowledgeId(id: string): Pick<KnowledgeItem, "type" | "group" | "name"> {
  const idParts = id.split(".");
  if (idParts.length != 3) throw new Error(`Invalid id ${id}`);
  return {
    type: idParts[0] as KnowledgeType,
    group: idParts[1],
    name: idParts[2],
  };
}

export function knowledgeItemId(item: KnowledgeItem): KnowledgeRef {
  return `${item.type}.${item.group}.${item.name}`;
}

export interface KnowledgeModule {
  id: string;
  label: string;
  iconName?: string;
  description?: string;
  dependencies?: string[];

  created?: DateTime;
  modified?: DateTime;

  concepts: ConceptType[];
  properties: PropertyType[];
  shapes: ShapeType[];
  enrichments: EnrichmentType[];
}

export type KnowledgeMetadata = Pick<KnowledgeModule, "id" | "label" | "iconName" | "description">;

/**
 * Convert UI alias string to list of strings
 * @param aliases
 * @returns
 */
export function convertAliases(aliases: string) {
  // Remove any empty aliases
  return aliases.split(/, */).flatMap((alias) => (alias.trim().length ? [alias] : []));
}

export function groupFromModuleName(moduleName: string): string {
  const parts = moduleName.split(".");
  if (!parts[1]) {
    throw new Error(`Invalid moduleName: ${moduleName}`);
  }
  return parts[1];
}

/**
 * Fetch an extra field, if present.
 * @param namespace
 * @param extra
 * @returns
 */
export function getExtraField<T>(
  namespace: string,
  extra?: Record<string, unknown>
): T | undefined {
  if (!extra) {
    return undefined;
  }
  const field = extra[namespace];
  if (field instanceof Object) {
    return field as T;
  }
  return undefined;
}

/**
 * Update a namespace within extra
 * @param namespace
 * @param extra
 * @param value
 * @returns
 */
export function updateExtraField<T>(
  namespace: string,
  extra?: Record<string, unknown>,
  value?: T
): Record<string, unknown> | undefined {
  if (!extra) {
    extra = {};
  }
  if (value === undefined) {
    delete extra[namespace];
  } else {
    extra[namespace] = value;
  }
  if (Object.entries(extra).length === 0) {
    return undefined;
  }
  return extra;
}

export const parsingSubplanner = "ct_subplanner_parsing";
export interface ParsingSubplannerExtra {
  parsing_significant?: boolean;
}

export const columnNameRegexSubplanner = "ct_subplanner_column_name_regex";
export interface ColumnNameRegexSubplannerExtra {
  include?: string[];
  exclude?: string[];
}

export const valueRegexSubplanner = "ct_subplanner_value_regex";
export interface ValueRegexSubplannerExtra {
  include?: string[];
  exclude?: string[];
}

function getDependenciesByModule(modules: AvailableModule[] | undefined): Map<string, Set<string>> {
  // Create map from module to immediate dependencies
  const dependenciesByModule = new Map(
    (modules || []).map((m) => [m.module.id, new Set(m.module.dependencies || [])])
  );
  return transitiveDependencies(dependenciesByModule);
}

export function dependsOn(
  modules: AvailableModule[] | undefined,
  module: AvailableModule
): string[] {
  return Array.from(getDependenciesByModule(modules).get(module.module.id) || []);
}

export function requiredBy(
  modules: AvailableModule[] | undefined,
  module: AvailableModule
): string[] {
  const requires = [];
  for (const [moduleId, deps] of getDependenciesByModule(modules).entries()) {
    if (deps.has(module.module.id)) {
      requires.push(moduleId);
    }
  }
  return requires;
}

function transitiveDependencies(nodes: Map<string, Set<string>>): Map<string, Set<string>> {
  const outputMap = new Map<string, Set<string>>();
  function getDeps(node: string): Set<string> {
    const dependencies = new Set([node]);
    const queue = [node];
    while (!isEmpty(queue)) {
      const current = queue.pop()!;
      const deps = nodes.get(current) || new Set();
      for (const dep of deps) {
        if (dependencies.has(dep)) {
          continue;
        }
        dependencies.add(dep);
        queue.push(dep);
      }
    }
    dependencies.delete(node);
    return dependencies;
  }
  for (const node of nodes.keys()) {
    outputMap.set(node, getDeps(node));
  }
  return outputMap;
}
