<template>
  <div
    class="text-[26px] font-bold leading-[16px] text-orange"
    :class="{ 'text-white opacity-25': chill }"
  >
    {{ character }}
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref, toRefs } from "vue";

const props = defineProps<{ chill?: boolean }>();
const { chill } = toRefs(props);

const CHARACTERS = ["◴", "◷", "◶", "◵"];

const characterIndex = ref(0);
let intervalId: number;

function cycleCharacter() {
  if (characterIndex.value === CHARACTERS.length - 1) {
    characterIndex.value = 0;
  } else {
    characterIndex.value += 1;
  }
}

const character = computed(() => CHARACTERS[characterIndex.value]);

onMounted(() => (intervalId = setInterval(cycleCharacter, 200)));
onBeforeUnmount(() => clearInterval(intervalId));
</script>
