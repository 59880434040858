import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex", { '-dark-hover': $props.darkHover }])
  }, [
    _createVNode($setup["Tooltip"], {
      class: "tooltip flex",
      disabled: $props.label == null || $props.disabled,
      theme: "icon-button-tooltip",
      placement: "bottom",
      distance: 2
    }, {
      popper: _withCtx(() => [
        _createTextVNode(_toDisplayString($props.label), 1 /* TEXT */)
      ]),
      default: _withCtx(() => [
        _createElementVNode("button", {
          class: _normalizeClass(["with-icon group flex cursor-pointer items-center justify-around rounded-[16px] border-none p-0", {
          'active:bg-orange dark:active:bg-black': !$props.disabled,
          'hover:bg-gray-200 dark:hover:bg-gray-900': !$props.disabled && !$props.darkHover,
          'hover:bg-gray-200 dark:hover:bg-black': !$props.disabled && $props.darkHover,
          'cursor-not-allowed opacity-50 dark:opacity-100': $props.disabled,
        }]),
          disabled: $props.disabled,
          style: _normalizeStyle({ width: $setup.buttonSizePx, height: $setup.buttonSizePx })
        }, [
          ($props.preText)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["svg-to-medium-gray", {
            'dark:group-hover:svg-to-white group-hover:svg-to-dark-gray dark:group-active:svg-to-white group-active:svg-to-gray2':
              !$props.disabled,
            'dark:svg-to-dark-gray svg-to-light-gray': $props.disabled,
          }])
              }, _toDisplayString($props.preText), 3 /* TEXT, CLASS */))
            : _createCommentVNode("v-if", true),
          _createElementVNode("img", {
            src: $setup.iconSrc,
            style: _normalizeStyle({ width: `${$setup.sizePx}px`, height: `${$setup.sizePx}px` }),
            class: _normalizeClass(["dark:svg-to-medium-gray svg-to-dark-gray max-w-none", {
            'dark:group-hover:svg-to-white group-hover:svg-to-dark-gray dark:group-active:svg-to-white group-active:svg-to-gray2':
              !$props.disabled,
            'svg-to-light-gray dark:svg-to-dark-gray': $props.disabled,
          }])
          }, null, 14 /* CLASS, STYLE, PROPS */, _hoisted_2)
        ], 14 /* CLASS, STYLE, PROPS */, _hoisted_1)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["disabled"])
  ], 2 /* CLASS */))
}