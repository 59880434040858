import { CTMap, getMapSection, MapSectionKey } from "@/common/lib/map";
import { defineStore } from "pinia";

interface State {
  selectedDataset: string | null;
  selectedConceptType: string | null;
  selectedConceptIndex: number | null;
  mergeCandidate: { conceptType: string; propertyType: string } | null;
}

export const usePerSourceConceptViewStore = defineStore("editor-perSourceConceptView", {
  state: (): State => ({
    selectedDataset: null,
    selectedConceptIndex: null,
    selectedConceptType: null,
    mergeCandidate: null,
  }),
  actions: {
    selectDataset(dataset: string | null) {
      this.selectedDataset = dataset;
      this.selectConceptType(null);
    },
    selectConceptType(conceptTypeId: string | null) {
      this.selectedConceptType = conceptTypeId;
      if (conceptTypeId == null) {
        this.selectedConceptIndex = null;
      } else {
        this.selectedConceptIndex = 0;
      }
    },
    selectConceptIndex(index: number) {
      this.selectedConceptIndex = index;
    },
    ensureSelectedConceptTypeInSet(concepts: string[]) {
      if (concepts.length === 0) {
        this.selectConceptType(null);
      } else {
        if (!this.selectedConceptType || !concepts.includes(this.selectedConceptType)) {
          this.selectConceptType(concepts[0]);
        }
      }
    },
    reconcileWithAvailableSources(maybeMap: CTMap | null) {
      const map = maybeMap || {};
      const datasets = Object.keys(getMapSection(map, MapSectionKey.InEncodings));
      // Try to select a reasonable source from those available
      if (this.selectedDataset == null || !datasets.includes(this.selectedDataset)) {
        if (datasets.length) {
          this.selectDataset(datasets[0]);
        } else {
          this.selectDataset(null);
        }
      } else if (this.selectedConceptType != null) {
        // Clear selected concept type if it is no longer available
        const iterClauses = getMapSection(map, MapSectionKey.InIterators);
        const iterKeys = Object.keys(iterClauses).filter(
          (key) => iterClauses[key].dataset === this.selectedDataset
        );
        const conClauses = getMapSection(map, MapSectionKey.InConcepts);
        const conClause = Object.keys(conClauses).find(
          (key) =>
            iterKeys.includes(conClauses[key].source_iterator) &&
            conClauses[key].concept_type === this.selectedConceptType
        );
        if (conClause == undefined) this.selectConceptType(null);
      }
    },
  },
});
