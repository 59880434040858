import useGraph from "@/common/composables/useGraph";
import { PropertyOpType, underlyingPropertyTypes } from "@/common/lib/derived";
import { LinkDescriptor } from "@/common/lib/graph";
import { ConceptKnowledgeRef, PropertyKnowledgeRef } from "@/common/lib/knowledge";
import { FILTER_TYPES_FOR_PROPERTY_VALUE_TYPE, Neighborhood, PathNode } from "@/common/lib/query";
import { GraphValue } from "@/common/lib/value";
import { chunk, compact, isArray, isString } from "lodash";
import { ExploreState, useExploreStore } from "../stores/explore";
import { ExploreFilter, propertyValueType } from "./explore";

export type ExploreBookmark = Required<
  Pick<ExploreState, "root_concept_type" | "columns" | "filters" | "order_by" | "group_by">
>;

export function allNeighborhoodsInBookmark(bookmark: ExploreBookmark) {
  return compact([
    ...bookmark.columns.map((c) => c.neighborhood),
    ...bookmark.filters.map((f) => f.neighborhood),
    ...(isArray(bookmark.group_by) ? bookmark.group_by.map((g) => g.neighborhood) : []),
  ]);
}

// Validates a bookmark against the current metagraph, returning a list of reasons
// why it can't be loaded as-is. An empty list means it should be good to go. This
// list is meant to be sent back to an LLM that created the bookmark in the first
// place so it can hopefully fix its mistakes. This could also be used to validate
// a user-created bookmark against schema drift, but the actual strings are not
// worth showing to the user.
//
// This function assumes that the bookmark is *structurally* valid, i.e. matches
// its type, and internally consistent. It returns errors for mismatches between
// the bookmark's references and what's available in the metagraph. If you pass it
// a structurally broken or self-inconsistent bookmark, it will throw runtime
// errors or at least produce invalid results.
export function validateBookmark(bookmark: ExploreBookmark) {
  const { getConceptsOfType } = useGraph(() => useExploreStore().metagraph);
  // Validate root_concept_type
  const root = bookmark.root_concept_type;
  if (getConceptsOfType(root).length == 0) {
    // Just return - nothing further useful to do
    return [`Root concept type ${bookmark.root_concept_type} not found`];
  }

  const problems: string[] = [];

  // Validate columns
  for (const column of bookmark.columns) {
    if (
      !isString(column.property_type) &&
      !Object.values(PropertyOpType).includes(column.property_type.op)
    ) {
      problems.push(`Column ${column.alias}: invalid op "${column.property_type.op}"`);
      continue; // Won't be able to determine prop types for an invalid op
      // If we teach the LLM how to do nested ops, we'll have to check the entire tree.
    }
    const pts = underlyingPropertyTypes(column.property_type);
    const errs = validateNeighborhoodReference(root, column.neighborhood, pts);
    problems.push(...errs.map((e) => `Column ${column.alias}: ${e}`));
  }

  // Validate filters
  for (const filter of bookmark.filters) {
    const refErrs = validateNeighborhoodReference(root, filter.neighborhood, [
      filter.property_type,
    ]);
    // No alias included in these error messages because the LLM won't know what they mean
    if (refErrs.length) {
      problems.push(...refErrs.map((e) => `Filter: ${e}`));
      continue; // Unsafe to try to validate filter on a missing property
    }
    problems.push(...validateFilter(filter));
  }

  // Validate group_by
  if (isArray(bookmark.group_by)) {
    for (const gb of bookmark.group_by) {
      const errs = validateNeighborhoodReference(root, gb.neighborhood, [gb.property_type]);
      problems.push(...errs.map((e) => `Group by: ${e}`));
    }
  }

  return compact(problems);
}

function validateNeighborhoodReference(
  rootConceptType: ConceptKnowledgeRef,
  neighborhood: Neighborhood = [],
  propertyTypes: PropertyKnowledgeRef[]
): string[] {
  const { getConceptsOfType, getLinkPartners, getConcept } = useGraph(
    () => useExploreStore().metagraph
  );
  let currentConcept = getConceptsOfType(rootConceptType)[0];
  for (const [linkDescr, conceptNode] of chunk(neighborhood, 2)) {
    const conceptTypeSought = (conceptNode as PathNode).concept_type;
    const partner = getLinkPartners(currentConcept.id, linkDescr as LinkDescriptor)
      .map(getConcept)
      .find((partnerConcept) => partnerConcept.type === conceptTypeSought);
    if (partner == null) {
      return [`${currentConcept.type} has no ${linkDescr} link to ${conceptTypeSought}`];
    } else {
      currentConcept = partner;
    }
  }
  // Now that we've found the concept, validate property types
  const problems: string[] = [];
  for (const ptype of propertyTypes) {
    if ((currentConcept.properties || []).find((p) => p.type === ptype) == null) {
      problems.push(`${currentConcept.type} has no property ${ptype}`);
    }
  }
  return problems;
}

function validateFilter(filter: ExploreFilter) {
  const problems: string[] = [];
  const propValueType = propertyValueType(filter.property_type);
  const validFilterTypes = FILTER_TYPES_FOR_PROPERTY_VALUE_TYPE[propValueType];
  if (!validFilterTypes.includes(filter.type)) {
    problems.push(
      `Filter on ${filter.property_type} has invalid type ${filter.type} - properties of type ${propValueType} only support filters of types: ${validFilterTypes.join(",")}`
    );
  }
  for (const values of filter.values) {
    for (const value of Object.values(values)) {
      const filterValueType = (value as GraphValue)._type;
      if (filterValueType !== propValueType) {
        problems.push(
          `Filter on ${filter.property_type} has value of type ${filterValueType}, a mismatch with the property's value type (${propValueType})`
        );
      }
    }
  }
  return problems;
}
