<template>
  <div :style="{ top, left }" class="absolute z-30 cursor-pointer">
    <div :class="{ '-root': isRoot }" @mousedown.prevent="startDragging" @click="click">
      <div
        class="absolute left-[-25px] top-[-25px] z-0 flex h-[50px] w-[50px] items-center justify-center rounded-full bg-gray-800"
        :class="{ 'border-2 border-white': isRoot }"
        :style="{ backgroundColor: color }"
      >
        <Icon class="icon" :name="iconName" size="xxl" color="gray2" />
      </div>
      <div
        class="relative right-[-8px] top-[13px] z-10 min-w-20 rounded-full border-2 border-gray-200 text-center text-gray-900 dark:border-gray-900"
        v-if="propertyCount > 0"
        :style="{ backgroundColor: color }"
      >
        {{ propertyCount }}
      </div>
      <div class="absolute left-[-50px] top-[25px] mt-10 w-[100px] text-center">
        {{ name }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Icon from "@/common/components/Icon.vue";
import { ConceptKnowledgeRef } from "@/common/lib/knowledge";
import useGraph from "@/common/composables/useGraph";
import useKnowledge from "@/common/composables/useKnowledge";
import { useExploreStore } from "@/reader/stores/explore";
import { computed, ref, toRefs } from "vue";
import { MISSING_CONCEPT_COLOR } from "@/common/lib/conceptColors";

const props = defineProps<{
  conceptType: ConceptKnowledgeRef;
  positionX: number;
  positionY: number;
}>();
const { conceptType, positionX, positionY } = toRefs(props);

const exploreStore = useExploreStore();

const emit = defineEmits<{
  (e: "move", x: number, y: number): void;
}>();

const dragStartX = ref(0);
const dragStartY = ref(0);
const preDragPositionX = ref(0);
const preDragPositionY = ref(0);
const isDragging = ref(false);

const { getConceptIconName, typeLabel } = useKnowledge();
const { getConceptsOfType } = useGraph(() => exploreStore.metagraph);

const name = computed(() => typeLabel(conceptType.value));
const iconName = computed(() => getConceptIconName(conceptType.value));

const top = computed(() => `${positionY.value}px`);
const left = computed(() => `${positionX.value}px`);

const isRoot = computed(() => exploreStore.root_concept_type === conceptType.value);
const propertyCount = computed(
  () => (getConceptsOfType(conceptType.value)[0].properties || []).length
);
const color = computed(
  () => exploreStore.conceptColors[conceptType.value] ?? MISSING_CONCEPT_COLOR
);

function startDragging(event: MouseEvent) {
  if (event.button != 0) return; // Only start dragging on left click
  dragStartX.value = event.pageX;
  dragStartY.value = event.pageY;
  preDragPositionX.value = positionX.value;
  preDragPositionY.value = positionY.value;
  document.addEventListener("mousemove", continueDragging);
  document.addEventListener("mouseup", finishDragging);
}

function continueDragging(event: MouseEvent) {
  const deltaX = event.pageX - dragStartX.value;
  const deltaY = event.pageY - dragStartY.value;
  if (deltaX != 0 || deltaY != 0) isDragging.value = true;
  emit("move", preDragPositionX.value + deltaX, preDragPositionY.value + deltaY);
  event.preventDefault();
}

function finishDragging(event: MouseEvent) {
  document.removeEventListener("mouseup", finishDragging);
  document.removeEventListener("mousemove", continueDragging);
  continueDragging(event); // Final position update
}

function click(event: MouseEvent) {
  if (isDragging.value) {
    // The 'click' at the end of a drag should have no effect
    event.stopImmediatePropagation();
    isDragging.value = false;
    return;
  }
  exploreStore.setRootConceptType(conceptType.value);
}
</script>
