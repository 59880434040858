import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pb-15" }
const _hoisted_2 = { class: "pb-10 text-lg" }
const _hoisted_3 = {
  key: 0,
  class: "loading"
}
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString($setup.visualization.title), 1 /* TEXT */),
    ($setup.queryResults.status === $setup.AsyncStatus.InProgress)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode($setup["Spinner"])
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.queryResults.status === $setup.AsyncStatus.Failed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($setup.queryResults.message), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    ($setup.queryResults.status === $setup.AsyncStatus.Succeeded)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent($setup.vizComponent), {
          key: 2,
          visualization: $setup.visualization,
          results: $setup.resultTable
        }, null, 8 /* PROPS */, ["visualization", "results"]))
      : _createCommentVNode("v-if", true)
  ]))
}