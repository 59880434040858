<template>
  <template v-for="group in subtable.groups ?? []" :key="group.id">
    <tr>
      <td
        class="cursor-pointer hover:bg-gray-100"
        :colspan="columns.length"
        @click="toggle(collapsed, group.id)"
        :style="indentStyle"
      >
        <div class="flex flex-row items-center font-bold">
          <Icon
            :name="collapsed.has(group.id) ? 'md:keyboard_arrow_up' : 'md:keyboard_arrow_down'"
            color="medium-gray"
            size="xl"
          />
          <div class="px-5 py-10">
            <GraphValueComponent :value="group.name" />
          </div>
        </div>
      </td>
    </tr>
    <Subtable
      v-if="!collapsed.has(group.id)"
      :subtable="group.contents"
      :columns="columns"
      :depth="depth + 1"
    />
  </template>
  <tr
    v-for="(row, index) in subtable.rows ?? []"
    :key="index"
    class="group light:hover:bg-gray-100 dark:odd:bg-gray-800 dark:odd:text-gray-100"
  >
    <td
      class="p-10 align-baseline"
      v-for="(colDetails, colIndex) in columns"
      :key="colDetails.alias"
    >
      <div :style="colIndex == 0 ? indentStyle : undefined">
        <template v-if="row[colDetails.alias].length > 0">
          <div
            v-for="(value, index) in row[colDetails.alias]"
            :key="index"
            :class="{ 'text-right': colDetails.isQuantitative }"
          >
            <GraphValueComponent :value="value" />
          </div>
        </template>
        <div class="opacity-50" :class="{ 'text-right': colDetails.isQuantitative }" v-else>-</div>
      </div>
    </td>
  </tr>
</template>

<script lang="ts" setup>
import { Subtable as SubtableType, Column } from "../Table.vue";
import GraphValueComponent from "@/common/components/GraphValue.vue";
import Icon from "@/common/components/Icon.vue";
import { toggle } from "@/common/lib/set";
import { computed, ref, Ref, toRefs } from "vue";

const props = defineProps<{ subtable: SubtableType; columns: Column[]; depth: number }>();
const { depth } = toRefs(props);

const collapsed: Ref<Set<string>> = ref(new Set());

const indentStyle = computed(() => ({ marginLeft: `${20 * depth.value}px` }));
</script>
