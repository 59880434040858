<template>
  <div class="isolate">
    <div
      class="absolute inset-0 z-10 flex flex-col items-center justify-center gap-10 text-black dark:text-white"
      :class="{ 'bg-white bg-opacity-85 dark:bg-black dark:bg-opacity-85': overlay }"
      v-if="async.status !== AsyncStatus.Succeeded"
    >
      <div v-if="async.status === AsyncStatus.Failed" class="text-xxxl">{{ async.message }}</div>
      <template v-if="async.status === AsyncStatus.InProgress">
        <Spinner />
        <div class="mt-10 text-xxxl" :class="{ 'opacity-60': overlay }">
          {{ async.message }}
        </div>
        <span v-if="currentWarehouse && currentWarehouse.state !== 'RUNNING'">
          Warehouse "{{ currentWarehouse.name }}" is {{ currentWarehouse.state }}
        </span>
      </template>
    </div>
    <div class="absolute inset-0"><slot /></div>
  </div>
</template>

<script lang="ts" setup>
import { useTimer } from "@/common/composables/useTimer";
import { environment } from "@/common/environments/environmentLoader";
import { Async, AsyncStatus } from "@/common/lib/async";
import { useDatabricksStore } from "@/common/stores/databricksStore";
import { storeToRefs } from "pinia";
import { toRefs, watch } from "vue";
import Spinner from "./Spinner.vue";

const props = withDefaults(defineProps<{ async: Async<unknown>; overlay?: boolean }>(), {
  overlay: true,
});
const { async } = toRefs(props);
const databricksStore = useDatabricksStore();
const { currentWarehouse } = storeToRefs(databricksStore);

const timer = useTimer(environment.requireNumber("LOADING_WAREHOUSE_CHECK_WAIT_MS"));

watch(
  async,
  (state) => {
    if (state.status === AsyncStatus.InProgress && currentWarehouse?.value?.state !== "RUNNING") {
      timer.start(async () => await databricksStore.getCurrentWarehouse());
    } else {
      timer.stop();
    }
  },
  { immediate: true }
);
</script>
