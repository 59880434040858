import useGraph from "@/common/composables/useGraph";
import useKnowledge from "@/common/composables/useKnowledge";
import { DerivedPropertyTerm, PropertyOpType } from "@/common/lib/derived";
import { Graph, GraphConcept, LinkDescriptor } from "@/common/lib/graph";
import { ConceptKnowledgeRef, PropertyKnowledgeRef } from "@/common/lib/knowledge";
import { FetchNPropertySet, FetchNRequest, Neighborhood } from "@/common/lib/query";
import { GraphValue, isValue } from "@/common/lib/value";
import { chunk, cloneDeep, isObject, isString, mapValues, pick } from "lodash";

export enum VisualizationType {
  DiscreteDistribution = "discrete_distribution",
  TimeDistribution = "time_distribution",
  Indicator = "indicator",
  PieChart = "pie_chart",
  Table = "table",
}

interface BaseVisualization {
  type: VisualizationType;
  title: string; // Maybe this is eventually optional
  query: Partial<FetchNRequest>;
  config: Record<string, unknown>;
}

export interface DiscreteDistributionVisualization extends BaseVisualization {
  type: VisualizationType.DiscreteDistribution;
  config: {
    category: string;
    category_name?: string; // If not specified, category is used
    value: string;
  };
}

export interface TimeDistributionVisualization extends BaseVisualization {
  type: VisualizationType.TimeDistribution;
  config: {
    time: string;
    value: string;
  };
}

export interface PieChartVisualization extends BaseVisualization {
  type: VisualizationType.PieChart;
  config: {
    category: string;
    category_name?: string; // If not specified, category is used
    value: string;
  };
}

export interface IndicatorVisualization extends BaseVisualization {
  type: VisualizationType.Indicator;
  config: {
    value: string;
  };
}

export interface TableVisualizationGroup {
  category: string;
  category_name?: string; // If not specified, category is used
}

export interface TableVisualization extends BaseVisualization {
  type: VisualizationType.Table;
  config: {
    columns: Array<{
      alias: string;
      label?: string; // If left out, we'll try to determine one automatically
    }>;
    groups?: TableVisualizationGroup[];
  };
}

export type Visualization =
  | DiscreteDistributionVisualization
  | TimeDistributionVisualization
  | IndicatorVisualization
  | PieChartVisualization
  | TableVisualization;

export function suggestedVisualizations(
  query: FetchNRequest,
  metagraph: Graph
): Record<string, Visualization> {
  const { getKnowledgeItem } = useKnowledge();
  const conceptType = getKnowledgeItem(query.concept_type);
  const suggestedVisualizations =
    (conceptType.extra?.["suggested_visualizations"] as Record<string, Visualization>) ?? {};
  const resolvedVisualizations: Record<string, Visualization> = {};
  for (const [visId, vis] of Object.entries(suggestedVisualizations)) {
    const resolved = resolveVisualizationToMetagraph(vis, query.concept_type, metagraph);
    if (resolved != null) {
      // Add base query filters (and neighbors to support filters)
      resolved.query.filters = [...(resolved.query.filters ?? []), ...(query.filters ?? [])];
      resolved.query.neighbors = {
        ...resolved.query.neighbors,
        ...mapValues(
          query.neighbors,
          (path) => path.map((step) => (isObject(step) ? pick(step, "concept_type", "tag") : step)) // Don't fetch properties
        ),
      };
      resolvedVisualizations[visId] = resolved;
    }
  }
  return resolvedVisualizations;
}

// Try to match all elements of a visualization query to graph elements present
// in the metagraph. If we can't, return null
function resolveVisualizationToMetagraph(
  vis: Visualization,
  conceptType: ConceptKnowledgeRef,
  metagraph: Graph
): Visualization | null {
  const { isAncestorOf } = useKnowledge();
  const { getLinkPartners, getConcept } = useGraph(() => metagraph);
  vis = cloneDeep(vis);

  // Find root concept
  const root = metagraph.concepts.find((mc) => mc.type === conceptType);
  if (root == null) return null;
  vis.query.concept_type = root.type;

  // Match base query properties
  const newProps = mapValues(vis.query.properties ?? {}, (p) => matchPropertyOrChildren(p, root));
  if (Object.values(newProps).includes(null)) return null;
  vis.query.properties = newProps as Record<string, DerivedPropertyTerm>;

  // Match neighborhoods
  let conceptCursor: GraphConcept;
  vis.query.neighbors ||= {};
  for (const [neighId, path] of Object.entries(vis.query.neighbors)) {
    conceptCursor = root;
    const newPath: Neighborhood = [];
    for (const [linkDescr, conceptNode] of chunk(path, 2)) {
      newPath.push(linkDescr);
      const baseConceptType = isString(conceptNode)
        ? (conceptNode as ConceptKnowledgeRef)
        : conceptNode.concept_type;
      const partnerIds = getLinkPartners(conceptCursor.id, linkDescr as LinkDescriptor);
      const matchingConceptId = partnerIds.find((partnerId) =>
        isAncestorOf(getConcept(partnerId).type, baseConceptType)
      );
      if (matchingConceptId == null) return null;
      conceptCursor = getConcept(matchingConceptId);
      if (isString(conceptNode)) {
        newPath.push(conceptCursor.type);
      } else {
        const properties = mapValues(conceptNode.properties || {}, (prop) =>
          matchPropertyOrChildren(prop, conceptCursor)
        );
        if (Object.values(properties).includes(null)) return null;
        newPath.push({
          ...conceptNode,
          concept_type: conceptCursor.type,
          properties: properties as Record<string, DerivedPropertyTerm>,
        });
      }
    }
    vis.query.neighbors[neighId] = newPath;
  }

  // TODO: match direct prop references in other parts of query (filter, group, order)
  return vis;
}

function matchPropertyOrChildren(
  baseType: DerivedPropertyTerm,
  concept: GraphConcept
): DerivedPropertyTerm | null {
  const { isAncestorOf } = useKnowledge();
  if (isString(baseType))
    return concept.properties?.find((mp) => isAncestorOf(mp.type, baseType))?.type ?? null;
  switch (baseType.op) {
    case PropertyOpType.Sum:
    case PropertyOpType.Avg:
    case PropertyOpType.Median:
    case PropertyOpType.Min:
    case PropertyOpType.Max:
    case PropertyOpType.DateTrunc:
    case PropertyOpType.Ntile: {
      const innerType = matchPropertyOrChildren(baseType.property_type, concept);
      if (innerType == null) return null;
      return { ...baseType, property_type: innerType as PropertyKnowledgeRef };
    }
    case PropertyOpType.Add:
    case PropertyOpType.Subtract: {
      const termTypes = baseType.terms.map((t) => matchPropertyOrChildren(t, concept));
      if (termTypes.includes(null)) return null;
      return { ...baseType, terms: termTypes as DerivedPropertyTerm[] };
    }
    case PropertyOpType.Multiply: {
      const facTypes = baseType.factors.map((t) => matchPropertyOrChildren(t, concept));
      if (facTypes.includes(null)) return null;
      return { ...baseType, factors: facTypes as DerivedPropertyTerm[] };
    }
    case PropertyOpType.Divide: {
      const divisorType = matchPropertyOrChildren(baseType.divisor, concept);
      const dividendType = matchPropertyOrChildren(baseType.dividend, concept);
      if (divisorType == null || dividendType == null) return null;
      return { ...baseType, divisor: divisorType, dividend: dividendType };
    }
    case PropertyOpType.DateDiff: {
      const divisorType = matchPropertyOrChildren(baseType.start, concept);
      const dividendType = matchPropertyOrChildren(baseType.end, concept);
      if (divisorType == null || dividendType == null) return null;
      return { ...baseType, start: divisorType, end: dividendType };
    }
    case PropertyOpType.Count:
      return baseType;
  }
}

export function singleValuesOrNull(propSet: FetchNPropertySet) {
  return mapValues(propSet, function (props) {
    if (props.length != 1) return null;
    if (!isValue(props[0])) return null; // Conveniently ignoring compound values for now
    return props[0] as GraphValue;
  });
}
