<template>
  <div class="flex items-center self-stretch">
    <div
      class="flex shrink-0 flex-grow basis-0 items-center gap-10 px-5 py-0 text-xxl font-bold text-gray-800 dark:text-gray-200"
    >
      <Icon name="electrical_services" size="xl" class="icon" :color="iconColor" />Connect to
      workspace
    </div>
  </div>
  <div
    class="flex max-w-[450px] flex-col items-start gap-10 self-stretch px-10 py-0"
    v-if="currentWorkspace && workspaces?.length && mode === 'view'"
  >
    <div
      class="flex items-start justify-between gap-10 self-stretch text-lg text-gray-900 dark:text-gray-100"
    >
      <span> Select a workspace </span>
      <TextButton label="Add workspace" @click="mode = 'create'" />
    </div>
    <form class="flex flex-col items-start gap-10 self-stretch">
      <Select
        label="Workspace"
        :options="workspaces"
        option-label="nickname"
        :option-value="(w) => w.id"
        :model-value="currentWorkspaceId"
        :clearable="false"
        @update:model-value="selectWorkspace"
      />
      <Select
        v-if="currentWorkspace && warehouses.length"
        label="Warehouse"
        v-model="currentWorkspace.current_warehouse_id"
        :options="warehouses"
        :option-label="formatWarehouse"
        :option-value="getSqlPath"
        @update:model-value="updateWarehouse"
        :clearable="false"
      />
    </form>
    <div class="flex items-center justify-end gap-10 self-stretch">
      <TextButton v-if="currentWorkspaceId" label="Delete" @click="deletingWorkspace = true" />
      <TextButton label="Edit" @click="mode = 'edit'" />
    </div>
  </div>

  <EditConnectionSettings
    v-if="mode !== 'view'"
    :mode="mode"
    :workspaceId="currentWorkspaceId"
    @save-workspace="updateWorkspace"
  />

  <Dialog
    v-if="deletingWorkspace"
    title="Delete workspace?"
    success-label="Delete"
    @succeeded="
      () => {
        databricksStore.deleteCurrentWorkspace();
        deletingWorkspace = false;
      }
    "
    @cancelled="deletingWorkspace = false"
  >
    Are you sure you want to delete workspace {{ currentWorkspace?.nickname }}? This cannot be
    undone.
  </Dialog>
</template>

<script lang="ts" setup>
import Select from "@/common/components/Select.vue";
import Icon from "@/common/components/Icon.vue";
import TextButton from "@/common/components/TextButton.vue";
import EditConnectionSettings from "@/common/components/settings/EditConnectionSettings.vue";
import { useDatabricksStore, Warehouse } from "@/common/stores/databricksStore";
import { onMounted, ref, inject, Ref, computed } from "vue";
import Dialog from "@/common/components/Dialog.vue";
import { useUserModuleStore } from "@/common/stores/userModuleStore";
import { DarkMode } from "@/common/lib/keys";
const databricksStore = useDatabricksStore();
import { storeToRefs } from "pinia";

const { currentWorkspace, currentWorkspaceId, workspaces, warehouses } =
  storeToRefs(databricksStore);

const mode = ref<"view" | "edit" | "create">("view");
const deletingWorkspace = ref(false);

async function connectToDatabricks() {
  await databricksStore.loadWorkspace();
  await databricksStore.listWorkspaces();
}

function updateWorkspace() {
  // databricksStore.currentWorkspace = { ...localWorkspace };
  // await databricksStore.save();
  // await databricksStore.checkConnected();
  // databricksStore.listWarehouses();
  // sourceBrowserStore.loadSystemTables(true);
  databricksStore.editInProgress = false;
  mode.value = "view";
}

async function selectWorkspace(workspaceName: string) {
  await databricksStore.selectWorkspace(workspaceName);
  await useUserModuleStore().boot();
}

onMounted(async () => {
  await connectToDatabricks();
  databricksStore.listWarehouses();
  if (!workspaces || workspaces.value?.length === 0) {
    mode.value = "create";
  }
});

const darkMode = inject(DarkMode) as Ref<boolean>;
const iconColor = computed(() => (darkMode.value ? "white" : "black"));

function formatWarehouse(warehouse: Warehouse): string {
  return `${warehouse.name} [${warehouse.cluster_size}]`;
}

function getSqlPath(warehouse: Warehouse) {
  return warehouse.sql_path;
}

async function updateWarehouse() {
  if (!currentWorkspace?.value) {
    return;
  }
  if (!currentWorkspace.value.current_warehouse_id) {
    return;
  }
  await databricksStore.updateWarehouse(currentWorkspace.value.current_warehouse_id);
  await useUserModuleStore().boot();
}
</script>
