import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5d8f0938"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "system-resources",
  "data-test": "system-resources"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "catalog-header" }
const _hoisted_4 = { class: "catalog-header-name" }
const _hoisted_5 = { class: "catalog-header-detail" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "dataset-header" }
const _hoisted_8 = { class: "name" }
const _hoisted_9 = { class: "detail" }
const _hoisted_10 = ["data-test", "onClick"]
const _hoisted_11 = { class: "header" }
const _hoisted_12 = { class: "tooltip-content" }
const _hoisted_13 = { class: "title" }
const _hoisted_14 = { class: "flex flex-row justify-between" }
const _hoisted_15 = {
  key: 0,
  class: "detail"
}
const _hoisted_16 = {
  key: 1,
  class: "detail"
}
const _hoisted_17 = {
  key: 2,
  class: "detail"
}
const _hoisted_18 = {
  key: 0,
  class: "no-matches"
}
const _hoisted_19 = {
  key: 1,
  class: "no-matches"
}
const _hoisted_20 = {
  key: 1,
  class: "in-progress"
}
const _hoisted_21 = {
  key: 2,
  class: "error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.status === $setup.AsyncStatus.Succeeded)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.catalogs, (catalog) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "table-group",
              key: catalog.name
            }, [
              _createElementVNode("div", {
                class: "catalog-level",
                onClick: ($event: any) => ($setup.toggleGroupCollapsed(catalog.name))
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode($setup["Disclosure"], {
                    class: "disclosure",
                    expanded: $setup.isGroupExpanded(catalog.name) || $setup.hasSearch
                  }, null, 8 /* PROPS */, ["expanded"]),
                  _createVNode($setup["Icon"], { name: "menu_book" }),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("span", null, _toDisplayString(catalog.name), 1 /* TEXT */)
                  ]),
                  _createElementVNode("div", _hoisted_5, _toDisplayString(catalog.schemas.length) + " " + _toDisplayString($setup.pluralize("Schemas", catalog.schemas.length)), 1 /* TEXT */)
                ])
              ], 8 /* PROPS */, _hoisted_2),
              ($setup.isGroupExpanded(catalog.name) || $setup.hasSearch)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(catalog.schemas, (schema) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "table-group",
                      key: schema.name
                    }, [
                      _createElementVNode("div", {
                        class: "dataset-level",
                        onClick: ($event: any) => ($setup.toggleGroupCollapsed(`${catalog.name}:${schema.name}`))
                      }, [
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode($setup["Disclosure"], {
                            class: "disclosure",
                            expanded: $setup.isGroupExpanded(`${catalog.name}:${schema.name}`) || $setup.hasSearch
                          }, null, 8 /* PROPS */, ["expanded"]),
                          _createVNode($setup["Icon"], {
                            name: "dataset",
                            color: "white"
                          }),
                          _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("span", null, _toDisplayString(schema.name), 1 /* TEXT */)
                          ]),
                          _createElementVNode("div", _hoisted_9, _toDisplayString(schema.tables.length) + " " + _toDisplayString($setup.pluralize("Table", schema.tables.length)), 1 /* TEXT */)
                        ])
                      ], 8 /* PROPS */, _hoisted_6),
                      ($setup.isGroupExpanded(`${catalog.name}:${schema.name}`) || $setup.hasSearch)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($setup.listSystemTables(catalog, schema), (table) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: _normalizeClass(["table-level", { '-selected': $setup.isSelected(table) }]),
                              key: `${table.group}-${table.name}`,
                              "data-test": `table-${table.name}`,
                              onClick: ($event: any) => ($setup.toggleSelected(table))
                            }, [
                              _createElementVNode("div", _hoisted_11, [
                                ($setup.isSelected(table))
                                  ? (_openBlock(), _createBlock($setup["Icon"], {
                                      key: 0,
                                      name: "checkbox-selected",
                                      color: "none"
                                    }))
                                  : (_openBlock(), _createBlock($setup["Icon"], {
                                      key: 1,
                                      name: "table",
                                      color: "white"
                                    })),
                                _createVNode($setup["Tooltip"], {
                                  placement: "right-start",
                                  delay: { show: 500, hide: 100 }
                                }, {
                                  popper: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_12, [
                                      _createElementVNode("div", _hoisted_13, _toDisplayString(`${catalog.name}.${schema.name}.${table.name}`), 1 /* TEXT */),
                                      _createTextVNode(" " + _toDisplayString(table.table.comment), 1 /* TEXT */)
                                    ])
                                  ]),
                                  default: _withCtx(() => [
                                    _createElementVNode("span", null, _toDisplayString(table.name), 1 /* TEXT */)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1024 /* DYNAMIC_SLOTS */)
                              ]),
                              _createElementVNode("div", _hoisted_14, [
                                (table.table.column_count)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(table.table.column_count) + " " + _toDisplayString($setup.pluralize("col", table.table.column_count)), 1 /* TEXT */))
                                  : _createCommentVNode("v-if", true),
                                (table.table.rows)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString($setup.rowCount(table.table.rows)), 1 /* TEXT */))
                                  : _createCommentVNode("v-if", true),
                                (table.table.bytes)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString($setup.formatBytes(table.table.bytes)), 1 /* TEXT */))
                                  : _createCommentVNode("v-if", true)
                              ])
                            ], 10 /* CLASS, PROPS */, _hoisted_10))
                          }), 128 /* KEYED_FRAGMENT */))
                        : _createCommentVNode("v-if", true)
                    ]))
                  }), 128 /* KEYED_FRAGMENT */))
                : _createCommentVNode("v-if", true)
            ]))
          }), 128 /* KEYED_FRAGMENT */)),
          ($setup.hasSearch && $setup.catalogs.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, " No resources match your search "))
            : _createCommentVNode("v-if", true),
          (!$setup.hasSearch && $setup.catalogs.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, "No resources found"))
            : _createCommentVNode("v-if", true)
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true),
    ($setup.status === $setup.AsyncStatus.InProgress)
      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
          _createVNode($setup["Spinner"])
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.status === $setup.AsyncStatus.Failed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
          _createVNode($setup["MessageBar"], { mode: "error" }, {
            title: _withCtx(() => [
              _createTextVNode(" Error loading tables ")
            ]),
            body: _withCtx(() => [
              _createTextVNode(_toDisplayString(($setup.system().catalogs as AsyncFailed).message), 1 /* TEXT */)
            ]),
            actions: _withCtx(() => [
              _createVNode($setup["TextButton"], {
                label: "Refresh",
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.refreshTables())),
                mode: "error"
              })
            ]),
            _: 1 /* STABLE */
          })
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}