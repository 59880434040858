<template>
  <div class="flex max-w-[450px] flex-col items-start gap-10 self-stretch px-10 py-0">
    <div
      class="item-start flex justify-between gap-10 self-stretch text-lg text-gray-900 dark:text-gray-100"
    >
      <span>
        Follow the instructions in the
        <a class="text-inherit visited:text-inherit" :href="databricksOAuthDocsUrl" target="_blank"
          >Databricks Documentation</a
        >
        to create a personal access token and paste it along with your Workspace URL below to
        connect your account.
      </span>
    </div>
    <form class="flex flex-col items-start gap-10 self-stretch">
      <Textbox label="Workspace Nickname" v-model="localWorkspace.nickname" :disabled="saving" />
      <Textbox
        label="Personal access token"
        v-model="localWorkspace.personal_access_token"
        type="password"
        autocomplete="on"
        :disabled="saving"
      />
      <Textbox label="Server hostname" v-model="localWorkspace.host" :disabled="saving" />
    </form>
    <div class="flex items-center justify-end gap-10 self-stretch" v-if="!warehouses.length">
      <TextButton label="Cancel" @click="cancelEditWorkspace()" />
      <TextButton label="Next" @click="updateWarehouses()" :disabled="!nextEnabled" />
    </div>
  </div>
  <div
    class="flex max-w-[450px] flex-col items-start gap-10 self-stretch px-10 py-0"
    v-if="warehouses.length"
  >
    <div
      class="item-start flex justify-between gap-10 self-stretch text-lg text-gray-900 dark:text-gray-100"
    >
      <span> Select a SQL warehouse </span>
    </div>
    <form class="flex flex-col items-start gap-10 self-stretch">
      <Dropdown
        label="Warehouse"
        v-model="localWorkspace.sql_http_path"
        :options="warehouses"
        option-label="name"
        :option-value="getSqlPath"
        :clearable="false"
      ></Dropdown>
    </form>
    <div class="flex items-center justify-end gap-10 self-stretch">
      <div></div>
      <div class="pb-5">
        <Spinner v-if="saving" />
      </div>
      <TextButton label="Cancel" @click="cancelEditWorkspace()" />
      <TextButton
        :label="connected ? 'Update' : 'Connect'"
        @click="connectToDatabricksWithCreds()"
        :disabled="saving || !saveEnabled"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Dropdown from "@/common/components/Dropdown.vue";
import Textbox from "@/common/components/Textbox.vue";
import TextButton from "@/common/components/TextButton.vue";
import { environment } from "@/common/environments/environmentLoader";
import { useDatabricksStore, Warehouse, Workspace } from "@/common/stores/databricksStore";
import Spinner from "@/common/components/Spinner.vue";
import { storeToRefs } from "pinia";
import { computed, onMounted, Ref, ref } from "vue";

const databricksStore = useDatabricksStore();

const props = defineProps<{ workspace?: Workspace }>();
const emits = defineEmits(["saveWorkspace"]);

const { connected } = storeToRefs(databricksStore);

const saving = ref(false);

const localWorkspace: Ref<Workspace> = ref({
  nickname: "",
  personal_access_token: "",
  host: "",
  sql_http_path: "",
});

const nextEnabled = computed(
  () =>
    localWorkspace.value.host.trim() !== "" &&
    localWorkspace.value.nickname.trim() !== "" &&
    localWorkspace.value.personal_access_token.trim() !== ""
);

const saveEnabled = computed(() => nextEnabled.value && localWorkspace.value.sql_http_path);

const databricksOAuthDocsUrl = environment.require("DATABRICKS_OAUTH_DOCS_URL");

const warehouses: Ref<Warehouse[]> = ref([]);

async function updateWarehouses() {
  if (nextEnabled.value) {
    warehouses.value = await databricksStore.listWarehousesForCreds(localWorkspace.value);
  } else {
    warehouses.value = [];
  }
}

function connectToDatabricksWithCreds() {
  saving.value = true;
  emits("saveWorkspace", localWorkspace.value);
}

function getSqlPath(warehouse: Warehouse) {
  return warehouse.sql_path;
}

function cancelEditWorkspace() {
  databricksStore.editInProgress = false;
}

onMounted(() => {
  if (props.workspace) {
    localWorkspace.value = { ...props.workspace };
    updateWarehouses();
  }
});
</script>
