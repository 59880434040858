<template>
  <div class="flex max-h-screen w-[450px] flex-col scroll-auto p-10">
    <div class="flex flex-row gap-5">
      <Icon name="star_filled" size="xl" class="icon" :color="iconColor" />
      <div class="self-center text-xxl text-gray-700 dark:text-gray-300">Bookmarks</div>
    </div>
    <div class="px-10 py-[7px] text-gray-500" v-if="typeof bookmarks === 'string'">
      {{ bookmarks }}
    </div>
    <div v-else>
      <div v-for="bookmark in bookmarks" :key="bookmark.key" class="flex items-center px-10">
        <div
          class="basis-full bg-white p-[7px] dark:bg-black"
          :class="{
            'cursor-default text-gray-300 dark:text-gray-700': bookmark.disabled,
            'text-gray-800 hover:cursor-pointer hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-900':
              !bookmark.disabled,
          }"
          @click="select(bookmark)"
        >
          <div class="flex flex-row items-center justify-between">
            <div>
              {{ bookmark.label }}
            </div>

            <Dropdown v-if="bookmark.submenu.length > 0" @click.stop>
              <template #popper>
                <Menu :items="bookmark.submenu" v-close-popper.all></Menu>
              </template>

              <Tooltip :delay="{ show: 1000, hide: 100 }">
                <template #popper>Manage {{ bookmark.label }}</template>
                <IconButton :name="bookmark.disabled ? 'md:error' : 'md:more_vert'" size="m" />
              </Tooltip>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Icon from "@/common/components/Icon.vue";
import IconButton from "@/common/components/IconButtonV2.vue";
import Menu from "@/common/components/Menu.vue";
import { Dropdown, Tooltip } from "floating-vue";
import { computed, Ref, inject } from "vue";
import { useUserModuleStore, Bookmark } from "@/common/stores/userModuleStore";
import { useExploreStore } from "../../stores/explore";
import { AsyncStatus } from "@/common/lib/async";
import { DarkMode } from "@/common/lib/keys";
import { isEmpty } from "lodash";
import { Query, validateQuery } from "@/common/lib/query";
defineProps<{ projectId: string }>();
const emit = defineEmits(["updateBookmark", "displayError", "deleteBookmark"]);
const userModuleStore = useUserModuleStore();
const exploreStore = useExploreStore();

interface submenuItem {
  key: string;
  label: string;
  action?: () => void;
}

interface BookmarkItem {
  label: string;
  key: string;
  disabled?: boolean;
  action?: () => void;
  submenu: submenuItem[];
}
const bookmarks = computed(() => {
  const items: BookmarkItem[] = [];
  const bookmarks = userModuleStore.bookmarks;
  if (bookmarks.status === AsyncStatus.Succeeded) {
    bookmarks.result.forEach((bookmark) => {
      const errors = validateQuery(bookmark.state, exploreStore.metagraph);
      items.push({
        key: bookmark.id,
        label: bookmark.metadata.name,
        disabled: !isEmpty(errors),
        action: () => openBookmark(bookmark.state),
        submenu: menuItems(bookmark, errors),
      });
    });
  } else if (bookmarks.status === AsyncStatus.Failed) {
    return bookmarks.message;
  } else if (isEmpty(items)) {
    return "No bookmarks yet.";
  }
  return items;
});

function select(item: BookmarkItem) {
  if (!item.disabled) {
    item.action?.();
  }
}

function openBookmark(query: Query) {
  exploreStore.loadQuery(query);
}

const darkMode = inject(DarkMode) as Ref<boolean>;
const iconColor = computed(() => {
  if (darkMode.value) {
    return "light-gray";
  } else {
    return "gray2";
  }
});

function menuItems(bookmark: Bookmark, errors: string[]) {
  const items = [
    {
      key: "rename",
      label: "Rename",
      action: () => emit("updateBookmark", bookmark),
    },
    {
      key: "rename",
      label: "Delete",
      action: () => emit("deleteBookmark", bookmark),
    },
  ];
  if (!isEmpty(errors)) {
    items.push({
      key: "errors",
      label: "Error details",
      action: () => emit("displayError", bookmark, errors),
    });
  }

  return items;
}
</script>
