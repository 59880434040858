import { PropertyKnowledgeRef } from "@/common/lib/knowledge";
import { Filter, FilterType, FilterValue, PropertyFilter } from "@/common/lib/query";

// This is a simplified filter interface, easier to build a UI around than
// the infinitely-deep filters exposed by the query engine
export interface ReaderFilter<T extends PropertyFilter = PropertyFilter> {
  alias: string;
  type: T["type"];
  property_type: PropertyKnowledgeRef;
  values: FilterValue<T>[];
  negated: boolean;
}

// Converts a ReaderFilter to a query engine filter, duplicating & wrapping
// it in logic as needed to actualize negation & multiple values
export function expandReaderFilter(filter: ReaderFilter, on_tag?: string): Filter {
  let filters: PropertyFilter[];
  if (filter.type === FilterType.Exists) {
    filters = [{ type: filter.type, property_type: filter.property_type, on_tag }];
  } else {
    filters = filter.values.map((value) => ({
      type: filter.type,
      property_type: filter.property_type,
      on_tag,
      ...value,
    })) as PropertyFilter[];
  }
  let outerFilter: Filter;
  if (filters.length === 1) {
    outerFilter = filters[0];
  } else {
    outerFilter = { type: FilterType.Or, filters }; // Later, make op configurable
  }
  if (filter.negated) outerFilter = { type: FilterType.Not, filter: outerFilter };
  return outerFilter;
}

export function filterIsComplete(filter: ReaderFilter) {
  return filter.type === FilterType.Exists || filter.values.length > 0;
}
