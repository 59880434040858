<template>
  <div class="pill-container">
    <div :style="{ color: color }">⬤</div>
    <div class="label">{{ label }}</div>
  </div>
</template>

<style lang="scss" scoped>
.label {
  font-size: 10px;
  margin-left: $thin-margin;
  padding-right: 5px;
}

.pill-container {
  font-size: 5px;
  background-color: var(--Dark-Gray);
  border-radius: 20px;
  padding: 5px;
  display: flex;
  align-items: center;
}
</style>

<script lang="ts" setup>
defineProps<{ color: string; label: string }>();
</script>
