<template>
  <div class="neighborhood">
    <div class="header">
      <Icon :name="conceptIconName" :color="{ dark: 'white', light: 'black' }" />
      <div class="label">{{ conceptTypeLabel }}</div>
      <IconButton name="table" size="l" tooltip="View all in table" @click="emit('pivot')" />
    </div>
    <div class="neighbors">
      <div
        v-for="neighbor of neighbors"
        class="neighbor"
        :class="{ '-with-link': neighbor.address != null }"
        :key="neighbor.key"
        @click="clickLink(neighbor.address)"
      >
        {{ neighbor.title }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.neighborhood {
  margin-top: $normal-margin;
}

.header {
  display: flex;
  align-items: center;

  .label {
    flex: 0 1 100%;
    text-transform: uppercase;
    color: $gray8;
    margin-left: $thin-margin;
  }
}

.neighbor {
  padding: 2px 0;

  &.-with-link {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>

<script setup lang="ts">
import Icon from "@/common/components/Icon.vue";
import IconButton from "@/common/components/IconButton.vue";
import { GraphConcept } from "@/common/lib/graph";
import useKnowledge from "@/common/composables/useKnowledge";
import { ConceptAddress, conceptAddress } from "@/reader/lib/concept";
import pluralize from "pluralize";
import { computed, toRefs } from "vue";

const props = defineProps<{ concepts: GraphConcept[] }>();
const { concepts } = toRefs(props);
const emit = defineEmits<{ (e: "navigate", address: ConceptAddress): void; (e: "pivot"): void }>();

const { getConceptIconName, typeLabel, conceptTitle } = useKnowledge();

const conceptType = () => concepts.value[0].type;

const conceptIconName = computed(() => getConceptIconName(conceptType()));
const conceptTypeLabel = computed(() => pluralize(typeLabel(conceptType()), concepts.value.length));

const neighbors = computed(function () {
  return concepts.value.map((concept) => ({
    key: concept.id,
    title: conceptTitle(concept),
    address: conceptAddress(concept),
  }));
});

function clickLink(address?: ConceptAddress) {
  if (address != null) emit("navigate", address);
}
</script>
