import { chunk, flatten, isString } from "lodash";
import { DerivedPropertyTerm } from "./derived";
import { GraphCompoundValue } from "./graph";
import {
  COMPOSITE_PROPERTY_VALUE_TYPE,
  ConceptKnowledgeRef,
  PropertyKnowledgeRef,
} from "./knowledge";
import { CTMap } from "./map";
import { GraphValue, GraphValueType } from "./value";

export interface FetchNRequest extends PathNode {
  neighbors?: Record<string, Neighborhood>;
  size?: number;
  order_by?: FetchNOrderBy[];
  filters?: Filter[];
  map?: CTMap;
  columns?: FetchNColumn[];
  create_named_test?: string;
}

export type Neighborhood = Array<string | PathNode>;

export const GROUP_BY_ALL = "all";

export interface PathNode {
  concept_type: ConceptKnowledgeRef;
  properties?: Record<string, DerivedPropertyTerm>;
  tag?: string;
  group_by?: PropertyKnowledgeRef[] | typeof GROUP_BY_ALL;
}

export interface FetchNColumn {
  alias: string;
  name?: string;
}

export interface FetchNResponse {
  paths: Array<RootAndNeighborRefs>;
  data: Record<string, FetchNConcept>;
  problems: FetchNProblem[];
}

export type RootAndNeighborRefs = Record<string, string[][]> & {
  root_id: string;
};

// https://github.com/claritype/ct/issues/3225
export const NON_NEIGHBORHOOD_PATH_KEYS = ["root_id", "truncated"];

export type FetchNPropertySet = Record<string, Array<GraphValue | GraphCompoundValue>>;

export interface FetchNConcept {
  concept_type: ConceptKnowledgeRef;
  properties: FetchNPropertySet;
  truncated: string[];
}

export interface FetchNProblem {
  level: "error" | "warning";
  type: string;
  message: string;
  context?: Record<string, unknown>;
}

export interface FetchNOrderBy {
  on: string;
  on_tag?: string;
  asc: boolean;
}

export enum FilterType {
  Equality = "eq",
  Range = "range",
  Not = "not",
  Or = "or",
  And = "and",
  Exists = "exists",
}

export const FILTER_TYPES_FOR_PROPERTY_VALUE_TYPE: Record<
  GraphValueType | typeof COMPOSITE_PROPERTY_VALUE_TYPE,
  FilterType[]
> = {
  [GraphValueType.Date]: [FilterType.Exists, FilterType.Equality, FilterType.Range],
  [GraphValueType.Time]: [FilterType.Exists, FilterType.Equality, FilterType.Range],
  [GraphValueType.Datetime]: [FilterType.Exists, FilterType.Equality, FilterType.Range],
  [GraphValueType.Duration]: [FilterType.Exists, FilterType.Equality, FilterType.Range],
  [GraphValueType.Float]: [FilterType.Exists, FilterType.Equality, FilterType.Range],
  [GraphValueType.Integer]: [FilterType.Exists, FilterType.Equality, FilterType.Range],
  [GraphValueType.String]: [FilterType.Exists, FilterType.Equality],
  [GraphValueType.Bool]: [FilterType.Exists, FilterType.Equality],
  [GraphValueType.Bytes]: [FilterType.Exists],
  [GraphValueType.Geopoint]: [FilterType.Exists],
  [COMPOSITE_PROPERTY_VALUE_TYPE]: [FilterType.Exists],
};

export interface BaseFilter {
  type: FilterType;
}

export interface BasePropertyFilter extends BaseFilter {
  property_type: PropertyKnowledgeRef;
  on_tag?: string;
}

export interface EqualityFilter extends BasePropertyFilter {
  type: FilterType.Equality;
  value: GraphValue;
}

export interface RangeFilter extends BasePropertyFilter {
  type: FilterType.Range;
  lt?: GraphValue;
  gt?: GraphValue;
  lte?: GraphValue;
  gte?: GraphValue;
}

export interface ExistenceFilter extends BasePropertyFilter {
  type: FilterType.Exists;
}

export interface NotFilter extends BaseFilter {
  type: FilterType.Not;
  filter: Filter;
}

export interface OrFilter extends BaseFilter {
  type: FilterType.Or;
  filters: Filter[];
}

export interface AndFilter extends BaseFilter {
  type: FilterType.And;
  filters: Filter[];
}

export type PropertyFilter = EqualityFilter | RangeFilter | ExistenceFilter;
export type Filter = PropertyFilter | NotFilter | OrFilter | AndFilter;

// This is a type for filters that are still in the process of being created.
// It makes everything but type and property_type optional.
export type FilterValue<T extends PropertyFilter = PropertyFilter> = Omit<
  T,
  "type" | "property_type" | "on_tag"
>;

export function normalizeNeighborhood(neighborhood?: Neighborhood): Neighborhood | undefined {
  if (neighborhood === undefined) return undefined;
  return flatten(
    chunk(neighborhood, 2).map(function ([linkDescr, concept]) {
      return [
        linkDescr,
        isString(concept) ? { concept_type: concept as ConceptKnowledgeRef } : concept,
      ];
    })
  );
}

export function findPropertyDefByAlias(query: Partial<FetchNRequest>, alias: string) {
  if (Object.hasOwn(query.properties ?? {}, alias)) return query.properties![alias];
  for (const neighborhood of Object.values(query.neighbors ?? {})) {
    for (const [_, neighbor] of chunk(normalizeNeighborhood(neighborhood), 2)) {
      const props = (neighbor as PathNode).properties;
      if (Object.hasOwn(props ?? {}, alias)) return props![alias];
    }
  }
  return null;
}
