<template>
  <div
    class="flex items-center justify-between border-b-[3px] border-orange bg-gray-900 pr-10"
    :class="{
      'border-red-500 bg-red-900': mode === 'error',
      'border-yellow-500 bg-yellow-900': mode === 'warning',
    }"
  >
    <div class="flex shrink-0 grow basis-0 flex-col items-start gap-5 p-10">
      <div class="self-stretch text-lg font-bold text-gray-200">
        <slot name="title"></slot>
      </div>
      <div class="text-base text-gray-200">
        <slot name="body"></slot>
      </div>
    </div>
    <slot name="actions"></slot>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  mode?: "info" | "warning" | "error";
}>();
</script>
