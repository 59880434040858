<template>
  <Chart :spec="spec" @select="handleSelect" />
</template>

<script lang="ts" setup>
import { FetchNPropertySet } from "@/common/lib/query";
import { DiscreteDistributionVisualization, singleValuesOrNull } from "@/reader/lib/visualization";
import { ComputedRef, Ref, computed, inject, toRefs } from "vue";
import * as vega from "vega";
import Chart from "@/common/components/Chart.vue";
import { DarkMode } from "@/common/lib/keys";
import { GraphValue, stringifyValue } from "@/common/lib/value";

const props = defineProps<{
  visualization: DiscreteDistributionVisualization;
  results: FetchNPropertySet[];
  width?: number;
}>();
const { visualization, results, width } = toRefs(props);

const emit = defineEmits<{ select: [alias: string, value: GraphValue | null] }>();

const darkMode = inject(DarkMode) as Ref<boolean>;

interface Datum {
  index: number;
  category: GraphValue | null;
  categoryId: string;
  categoryName: [string, string];
  value: number;
  tooltip: Record<string, string>;
}

const data = computed(() =>
  results.value.map(function (row, index): Datum {
    const config = visualization.value.config;
    const values = singleValuesOrNull(row);
    const category = values[config.category];
    const categoryId = category ? stringifyValue(category) : "";
    const categoryName = config.category_name ? values[config.category_name] : category;
    const categoryNameStr = categoryName ? stringifyValue(categoryName) : "-";
    const value = Number(values[config.value]?.value);
    return {
      index,
      category,
      categoryId,
      categoryName: [categoryNameStr, categoryId],
      value: Number(value),
      tooltip: { [categoryNameStr]: String(value) },
    };
    // Why does categoryName include the id as well? Because both the domain and
    // range of the scale mapping categoryId to categoryName must be unique, so
    // without this, duplicate categoryNames cause brokenness
  })
);

const height = computed(() => 30 + results.value.length * 25);

const spec: ComputedRef<vega.Spec> = computed(function () {
  const spec: vega.Spec = {
    width: (width.value ?? 370) - 10,
    height: height.value,
    padding: 5,
    autosize: "fit",
    data: [
      {
        name: "table",
        values: data.value,
      },
    ],
    signals: [
      {
        name: "selection",
        value: null,
        on: [{ events: "@bar:click", update: "datum.category" }],
      },
    ],
    scales: [
      {
        name: "x",
        type: "linear",
        domain: { data: "table", field: "value" },
        range: "width",
        nice: true,
      },
      {
        name: "y",
        type: "band",
        domain: { data: "table", field: "categoryId" },
        range: "height",
        padding: 0.2,
      },
      {
        name: "y_names",
        type: "ordinal",
        domain: { data: "table", field: "categoryId" },
        range: { data: "table", field: "categoryName" },
      },
    ],
    axes: [
      {
        orient: "bottom",
        format: "s",
        scale: "x",
        tickCount: 5,
        labelColor: darkMode.value ? "white" : "black",
        labelFontSize: 12,
      },
      {
        scale: "y",
        orient: "left",
        labelColor: darkMode.value ? "white" : "black",
        labelLimit: 120,
        domain: false,
        ticks: false,
        labelPadding: 5,
        encode: {
          labels: {
            update: {
              text: { signal: "scale('y_names', datum.value)[0]" },
            },
          },
        },
      },
    ],
    marks: [
      {
        name: "bar",
        type: "rect",
        from: { data: "table" },
        encode: {
          update: {
            x: { scale: "x", value: 0 },
            x2: { scale: "x", field: "value" },
            y: { scale: "y", field: "categoryId" },
            height: { scale: "y", band: 1 },
            fill: { value: "#f75e0e" },
            tooltip: { signal: "datum.tooltip" },
          },
          hover: {
            fill: { value: "#F9AA81" },
            cursor: { value: "pointer" },
          },
        },
      },
    ],
  };
  return spec;
});

function handleSelect(category: unknown) {
  emit("select", visualization.value.config.category, category as GraphValue | null);
}
</script>
