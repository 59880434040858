import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "problems" }
const _hoisted_2 = { class: "select-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.problems, (problem, index) => {
      return (_openBlock(), _createBlock($setup["MessageBar"], {
        mode: problem.level,
        key: index
      }, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(problem.level === "warning" ? "Warning" : "Error"), 1 /* TEXT */)
        ]),
        body: _withCtx(() => [
          _createElementVNode("span", _hoisted_2, _toDisplayString(problem.message), 1 /* TEXT */)
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["mode"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}