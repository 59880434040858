import useGraph from "@/common/composables/useGraph";
import { LinkDescriptor, linkPartner } from "@/common/lib/graph";
import { ConceptKnowledgeRef, ROLE_LINK_TYPE } from "@/common/lib/knowledge";
import { every, last } from "lodash";
import { useExploreStore } from "../stores/explore";

export interface ExploreTreeNode {
  conceptType: ConceptKnowledgeRef;
  linkDescriptor?: LinkDescriptor; // Not present for root
  elidedRoleType?: ConceptKnowledgeRef;
}

export type ExploreTreePath = ExploreTreeNode[];

export function childPaths(path: ExploreTreePath, elideRoles = true): ExploreTreePath[] {
  const exploreStore = useExploreStore();
  const { getLinksWith, getConceptsOfType, getConcept } = useGraph(() => exploreStore.metagraph);
  const conceptType = last(path)!.conceptType;
  const conceptId = getConceptsOfType(conceptType)[0].id;
  const links = getLinksWith(conceptId).filter(function (link) {
    const partner = linkPartner(link, conceptId);
    if (partner == null) return false;
    if (path.length > 1 && getConcept(partner).type === path[path.length - 2].conceptType)
      return false;
    return true;
  });
  return links.flatMap(function (link) {
    const concept = getConcept(linkPartner(link, conceptId));
    let linkDescriptor = LinkDescriptor.RelatedTo;
    if (link.type === ROLE_LINK_TYPE) {
      linkDescriptor = link.from === conceptId ? LinkDescriptor.AsA : LinkDescriptor.RoleOf;
    }
    const newNode = { conceptType: concept.type, linkDescriptor };
    if (elideRoles) {
      // Peek one level deeper. If the current level seems to be an empty role concept,
      // leave it out and enrich the next level with information about it instead
      const elidablePaths = childPaths([...path, newNode], false);
      if (
        linkDescriptor !== LinkDescriptor.RoleOf &&
        (concept.properties ?? []).length === 0 &&
        elidablePaths.length > 0
      ) {
        const elidedPaths = elidablePaths.map(function (deeperPath) {
          const deeperNode = last(deeperPath);
          if (
            (linkDescriptor === LinkDescriptor.AsA &&
              deeperNode?.linkDescriptor === LinkDescriptor.RelatedTo) ||
            (linkDescriptor === LinkDescriptor.RelatedTo &&
              deeperNode?.linkDescriptor === LinkDescriptor.RoleOf)
          ) {
            return [...path, newNode, { ...deeperNode, elidedRoleType: newNode.conceptType }];
          }
          return null;
        });
        // If not every child can be represented this way, give up and show the node as usual
        if (every(elidedPaths, (ep) => ep != null)) return elidedPaths as ExploreTreePath[];
      }
    }

    return [[...path, newNode]];
  });
}

export function pathsEquivalent(path1: ExploreTreePath, path2: ExploreTreePath) {
  if (path1.length !== path2.length) return false;
  for (let i = 0; i != path1.length; i++) {
    if (path1[i].conceptType !== path2[i].conceptType) return false;
    if (path1[i].linkDescriptor !== path2[i].linkDescriptor) return false;
  }
  return true;
}
