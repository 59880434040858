import useGraph from "@/common/composables/useGraph";
import useKnowledge from "@/common/composables/useKnowledge";
import { GraphConcept, LinkDescriptor, linkPartner } from "@/common/lib/graph";
import {
  ConceptKnowledgeRef,
  ConceptType,
  PropertyKnowledgeRef,
  ROLE_LINK_TYPE,
} from "@/common/lib/knowledge";
import { getMapClausesWhere, MapSectionKey } from "@/common/lib/map";
import { FetchNRequest, FilterType, Neighborhood } from "@/common/lib/query";
import { GraphValue, isValue } from "@/common/lib/value";
import { every, fromPairs } from "lodash";
import { useExploreStore } from "../stores/explore";

type ConceptKeySet = Record<PropertyKnowledgeRef, GraphValue>;

export interface ConceptAddress {
  conceptType: ConceptKnowledgeRef;
  keys: ConceptKeySet;
}

export function buildConceptQuery(address: ConceptAddress): FetchNRequest {
  const { getConceptsOfType } = useGraph(() => useExploreStore().metagraph);
  const concept = getConceptsOfType(address.conceptType)[0];
  return {
    concept_type: address.conceptType,
    neighbors: buildNeighborhoods(concept.id),
    size: 1,
    filters: Object.entries(address.keys).map(([pt, value]) => ({
      type: FilterType.Equality,
      property_type: pt as PropertyKnowledgeRef,
      value,
    })),
    properties: fromPairs((concept.properties || []).map((p) => [p.type, p.type])),
  };
}

function buildNeighborhoods(conceptId: string) {
  const { getLinksWith, getConcept } = useGraph(() => useExploreStore().metagraph);
  const { getKnowledgeItem, isAncestorOf } = useKnowledge();
  const neighborhoods: Record<string, Neighborhood> = {};
  for (const link of getLinksWith(conceptId)) {
    const partner = linkPartner(link, conceptId);
    if (partner == null) continue; // Don't handle self-links
    let linkDescriptor = LinkDescriptor.RelatedTo;
    let neighborConcept;
    if (link.from === conceptId) {
      if (link.type === ROLE_LINK_TYPE) linkDescriptor = LinkDescriptor.AsA;
      neighborConcept = getConcept(link.to);
    } else {
      if (link.type === ROLE_LINK_TYPE) linkDescriptor = LinkDescriptor.RoleOf;
      neighborConcept = getConcept(link.from);
    }
    const props: Set<PropertyKnowledgeRef> = new Set();
    const titles = (getKnowledgeItem(neighborConcept.type) as ConceptType).title_properties;
    for (const prop of neighborConcept.properties || []) {
      if (isConceptPageLinkable(neighborConcept.type, prop.type)) props.add(prop.type);
      for (const titleProp of titles) if (isAncestorOf(prop.type, titleProp)) props.add(prop.type);
    }
    // If we can't construct a title or a link, no point in including this
    if (props.size == 0) continue;
    neighborhoods[`${linkDescriptor}-${neighborConcept.type}`] = [
      linkDescriptor,
      {
        concept_type: neighborConcept.type,
        properties: fromPairs(Array.from(props).map((prop) => [prop, prop])),
      },
    ];
  }
  return neighborhoods;
}

export function isConceptPageLinkable(
  conceptType: ConceptKnowledgeRef,
  propertyType: PropertyKnowledgeRef
) {
  const keySet = keySetsForConceptType(conceptType).find(
    (ks) => ks.length == 1 && ks[0] === propertyType
  );
  return !!keySet;
}

export function conceptAddress(concept: GraphConcept): ConceptAddress | undefined {
  const keySets = keySetsForConceptType(concept.type);
  for (const keySet of keySets) {
    const keys: Record<string, unknown> = {};
    for (const ptype of keySet) {
      const prop = (concept.properties || []).find((p) => p.type === ptype);
      keys[ptype] = prop?.value;
    }
    if (every(Object.values(keys), (k) => isValue(k))) {
      return { conceptType: concept.type, keys: keys as ConceptKeySet };
    }
  }
  return undefined;
}

// Which sets of properties can be used as ConceptAddress keys for a concept type?
function keySetsForConceptType(conceptType: ConceptKnowledgeRef) {
  const exploreStore = useExploreStore();
  const resolutions = getMapClausesWhere(
    exploreStore.map!,
    MapSectionKey.Resolutions,
    (c) => c.type === conceptType
  );
  return Object.values(resolutions).map((r) => r.on);
}
