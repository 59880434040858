<template>
  <div class="flex items-center self-stretch">
    <div
      class="flex shrink-0 flex-grow basis-0 items-center gap-10 px-5 py-0 text-xxl font-bold text-gray-800 dark:text-gray-200"
    >
      <Icon name="electrical_services" size="xl" class="icon" :color="iconColor" />Connect to
      workspace
    </div>
  </div>
  <div
    class="flex max-w-[450px] flex-col items-start gap-10 self-stretch px-10 py-0"
    v-if="
      databricksStore.currentWorkspace &&
      databricksStore.workspaces?.length &&
      !databricksStore.editInProgress
    "
  >
    <div
      class="flex items-start justify-between gap-10 self-stretch text-lg text-gray-900 dark:text-gray-100"
    >
      <span> Select a workspace </span>
      <TextButton label="Add workspace" @click="databricksStore.addWorkspace" />
    </div>
    <form class="flex flex-col items-start gap-10 self-stretch">
      <Dropdown
        label="Workspace"
        :options="databricksStore.workspaces"
        option-label="nickname"
        :option-value="(w) => w.host"
        :model-value="databricksStore.currentWorkspaceHost"
        :clearable="false"
        @update:model-value="selectWorkspace"
      ></Dropdown>
    </form>
    <div class="flex items-center justify-end gap-10 self-stretch">
      <TextButton
        v-if="databricksStore.currentWorkspaceHost"
        label="Delete"
        @click="deletingWorkspace = true"
      />
      <TextButton
        label="Edit"
        @click="databricksStore.editInProgress = !databricksStore.editInProgress"
        :disabled="databricksStore.editInProgress"
      />
    </div>
  </div>

  <EditConnectionSettings
    v-if="databricksStore.editInProgress"
    :workspace="databricksStore.currentWorkspace"
    @save-workspace="updateWorkspace"
  />

  <Dialog
    v-if="deletingWorkspace"
    title="Delete workspace?"
    success-label="Delete"
    @succeeded="
      () => {
        databricksStore.deleteCurrentWorkspace();
        deletingWorkspace = false;
      }
    "
    @cancelled="deletingWorkspace = false"
  >
    Are you sure you want to delete workspace {{ databricksStore.currentWorkspace?.nickname }}? This
    cannot be undone.
  </Dialog>
</template>

<script lang="ts" setup>
import Dropdown from "@/common/components/Dropdown.vue";
import Icon from "@/common/components/Icon.vue";
import TextButton from "@/common/components/TextButton.vue";
import EditConnectionSettings from "@/common/components/EditConnectionSettings.vue";
import { useDatabricksStore, Workspace } from "@/common/stores/databricksStore";
import { onMounted, ref, inject, Ref, computed } from "vue";
import Dialog from "@/common/components/Dialog.vue";
import { useUserModuleStore } from "@/common/stores/userModuleStore";
import { useSourceBrowserStore } from "@/common/stores/sourceBrowser";
import { DarkMode } from "@/common/lib/keys";
const databricksStore = useDatabricksStore();
const sourceBrowserStore = useSourceBrowserStore();

const deletingWorkspace = ref(false);

async function connectToDatabricks() {
  await databricksStore.loadWorkspace();
  await databricksStore.listWorkspaces();
}

async function updateWorkspace(localWorkspace: Workspace) {
  databricksStore.currentWorkspace = { ...localWorkspace };
  await databricksStore.save();
  await databricksStore.checkConnected();
  databricksStore.listWarehouses();
  sourceBrowserStore.loadSystemTables("dbx", true);
  databricksStore.editInProgress = false;
}

async function selectWorkspace(workspaceName: string) {
  await databricksStore.selectWorkspace(workspaceName);
  await useUserModuleStore().boot();
}

onMounted(() => {
  connectToDatabricks();
  databricksStore.listWarehouses();
});
const darkMode = inject(DarkMode) as Ref<boolean>;
const iconColor = computed(() => {
  if (darkMode.value) {
    return "white";
  } else {
    return "black";
  }
});
</script>
