<template>
  <div class="system-resources" data-test="system-resources">
    <template v-if="status === AsyncStatus.Succeeded">
      <div class="table-group" v-for="catalog in catalogs" :key="catalog.name">
        <div class="catalog-level" @click="toggleGroupCollapsed(catalog.name)">
          <div class="catalog-header">
            <Disclosure class="disclosure" :expanded="isGroupExpanded(catalog.name) || hasSearch" />
            <Icon name="menu_book" />
            <div class="catalog-header-name">
              <span>{{ catalog.name }}</span>
            </div>
            <div class="catalog-header-detail">
              {{ catalog.schemas.length }} {{ pluralize("Schemas", catalog.schemas.length) }}
            </div>
          </div>
        </div>
        <template v-if="isGroupExpanded(catalog.name) || hasSearch">
          <div class="table-group" v-for="schema in catalog.schemas" :key="schema.name">
            <div
              class="dataset-level"
              @click="toggleGroupCollapsed(`${catalog.name}:${schema.name}`)"
            >
              <div class="dataset-header">
                <Disclosure
                  class="disclosure"
                  :expanded="isGroupExpanded(`${catalog.name}:${schema.name}`) || hasSearch"
                />
                <Icon name="dataset" color="white" />
                <div class="name">
                  <span>{{ schema.name }}</span>
                </div>
                <div class="detail">
                  {{ schema.tables.length }} {{ pluralize("Table", schema.tables.length) }}
                </div>
              </div>
            </div>
            <template v-if="isGroupExpanded(`${catalog.name}:${schema.name}`) || hasSearch">
              <div
                v-for="table in listSystemTables(provider!, catalog, schema)"
                class="table-level"
                :class="{ '-selected': isSelected(table) }"
                :key="`${table.group}-${table.name}`"
                :data-test="`table-${table.name}`"
                @click="toggleSelected(table)"
              >
                <div class="header">
                  <Icon v-if="isSelected(table)" name="checkbox-selected" color="none" />
                  <Icon v-else name="table" color="white" />
                  <Tooltip placement="right-start" :delay="{ show: 500, hide: 100 }">
                    <template #popper>
                      <div class="tooltip-content">
                        <div class="title">
                          {{ `${catalog.name}.${schema.name}.${table.name}` }}
                        </div>
                        {{ table.table.comment }}
                      </div>
                    </template>
                    <span>{{ table.name }}</span>
                  </Tooltip>
                </div>
                <div class="flex flex-row justify-between">
                  <div class="detail" v-if="table.table.column_count">
                    {{ table.table.column_count }} {{ pluralize("col", table.table.column_count) }}
                  </div>
                  <div class="detail" v-if="table.table.rows">
                    {{ rowCount(table.table.rows) }}
                  </div>
                  <div class="detail" v-if="table.table.bytes">
                    {{ formatBytes(table.table.bytes) }}
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>

      <div class="no-matches" v-if="hasSearch && catalogs.length === 0">
        No resources match your search
      </div>
      <div class="no-matches" v-if="!hasSearch && catalogs.length === 0">No resources found</div>
    </template>
    <div class="in-progress" v-if="status === AsyncStatus.InProgress">
      <Spinner />
    </div>
    <div class="error" v-if="status === AsyncStatus.Failed">
      <MessageBar mode="error">
        <template #title> Error loading tables </template>
        <template #body>
          {{ (sourceBrowserStore.catalogs as AsyncFailed).message }}
        </template>
        <template #actions>
          <TextButton label="Refresh" @click="refreshTables()" mode="error" />
        </template>
      </MessageBar>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$system-resources-title-font-size: 12px;
$system-resources-title-font-weight: 500;
$system-resources-detail-font-size: 11px;
.system-resources {
  margin: $normal-margin 0;
  user-select: none;
  max-height: fit-content;
}
.info-icon {
  opacity: 50%;
}
.group-disclosure {
  padding: $normal-margin $wider-margin;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: $gray1;
  }

  .name {
    margin-left: $thin-margin;
  }
}

.disclosure {
  width: 20px;
  height: 20px;
  justify-content: center;
}
.catalog-level {
  display: flex;
  padding: $thin-margin $normal-margin;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  &:hover {
    background: var(--Canvas);
  }
}

.catalog-header {
  display: flex;
  align-items: center;
  gap: $normal-margin;
  align-self: stretch;

  &-name {
    display: flex;
    flex-direction: row;
    gap: $thin-margin;
    justify-content: flex-start;
    align-items: center;
    flex: 1 0 0;
    color: var(--Light-Gray);
    font-size: $system-resources-title-font-size;
    font-weight: $system-resources-title-font-weight;
    word-break: break-all;
  }
  &-detail {
    color: var(--Medium-Gray);
    text-align: right;
    font-size: $system-resources-detail-font-size;
  }
}

.dataset {
  &-level {
    display: flex;
    padding: $thin-margin $wide-margin $thin-margin $wider-margin;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &:hover {
      background: var(--Canvas);
    }
  }

  &-header {
    display: flex;
    align-items: center;
    gap: $normal-margin;
    align-self: stretch;

    .name {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: $thin-margin;
      flex: 1 0 0;

      color: var(--Light-Gray);
      font-size: $system-resources-title-font-size;
      word-break: break-all;
    }

    .detail {
      color: var(--Medium-Gray);
      text-align: right;
      font-size: $system-resources-detail-font-size;
    }
  }

  &-subheader {
    display: flex;
    padding-left: $ludicrous-margin;
    align-items: center;
    gap: $wide-margin;
    align-self: stretch;

    color: var(--Medium-Gray);
    font-size: $system-resources-detail-font-size;
  }
}

.table-level {
  display: flex;
  padding: $thin-margin $wide-margin $thin-margin calc($wide-margin * 4);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: $thin-margin;

  cursor: pointer;

  &:hover {
    background: var(--Canvas);
  }

  .header {
    display: flex;
    align-items: center;
    gap: $thin-margin;
    align-self: stretch;
    word-break: break-all;

    .name {
      display: flex;
      flex-direction: column;
      justify-content: center;
      // flex: 1 0 0;

      color: var(--Light-Gray);
      font-size: $system-resources-title-font-size;
    }
  }
  .detail {
    color: var(--Medium-Gray);
    font-size: $system-resources-detail-font-size;
    padding-left: calc($wider-margin + $normal-margin);
  }
}
.table {
  display: flex;
  padding: $thin-margin $wider-margin $thin-margin ($wider-margin + $wide-margin);
  align-items: center;
  cursor: pointer;

  &:hover {
    background: $gray1;
  }

  .name {
    margin-left: $thin-margin;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $chill-text;
  }
}

.in-progress {
  text-align: center;
}

.no-matches {
  padding: $normal-margin;
  color: $dim-text;
  font-style: italic;
  text-align: center;
}

.tooltip-content {
  max-width: $tooltip-width;
  .title {
    word-break: break-all;
    margin-bottom: $thin-margin;
  }
}
</style>

<script lang="ts" setup>
import Icon from "@/common/components/Icon.vue";
import MessageBar from "@/common/components/MessageBar.vue";
import TextButton from "@/common/components/TextButton.vue";
import { AsyncFailed, asyncResultOr, AsyncStatus } from "@/common/lib/async";
import Disclosure from "@/common/components/Disclosure.vue";
import Spinner from "@/common/components/Spinner.vue";
import { Catalog, Schema, Table } from "@/common/stores/catalog";
import { SystemTable, useSourceBrowserStore } from "@/common/stores/sourceBrowser";
import pluralize from "pluralize";
import { computed, onMounted } from "vue";
import { Tooltip } from "floating-vue";
import { every, find } from "lodash";
import { environment } from "../environments/environmentLoader";
import { formatBytes } from "@/common/lib/text";
import { SourceType } from "@/common/lib/map";

const sourceBrowserStore = useSourceBrowserStore();

const status = computed(() => sourceBrowserStore.catalogs.status);
const provider = computed(() => sourceBrowserStore.provider);
const catalogs = computed(function () {
  const catalogs = asyncResultOr(sourceBrowserStore.catalogs, []);
  const filteredCatalogs = filterCatalogs(catalogs, sourceBrowserStore.search);
  return filteredCatalogs;
});
const hasSearch = computed(() => sourceBrowserStore.search.length > 0);

function filterCatalogs(catalogs: Catalog[], searchString: string): Catalog[] {
  if (searchString === "") {
    return catalogs;
  }
  return catalogs.flatMap((catalog) => {
    const schemas = catalog.schemas.flatMap((s) => filterSchema(s, searchString, catalog.name));
    if (schemas.length === 0) {
      return [];
    }
    catalog = Object.assign({}, catalog, { schemas });
    return [catalog];
  });
}

function filterSchema(schema: Schema, searchString: string, catalogName: string): Schema[] {
  const tables = schema.tables.filter((t) =>
    filterTable(t.name, searchString, catalogName, schema.name)
  );
  if (tables.length === 0) {
    return [];
  }
  schema = Object.assign({}, schema, { tables });
  return [schema];
}

function listSystemTables(provider: SourceType, catalog: Catalog, schema: Schema): SystemTable[] {
  return schema.tables.map((t) => toSystemTable(provider, catalog, schema, t));
}

function toSystemTable(
  provider: SourceType,
  catalog: Catalog,
  schema: Schema,
  table: Table
): SystemTable {
  return {
    provider,
    catalog: catalog.name,
    schema: schema.name,
    group: `${catalog.name}:${schema.name}`,
    name: table.name,
    table,
  };
}

function filterTable(
  tableName: string,
  searchString: string,
  catalogName: string,
  schemaName: string
): boolean {
  const searchTokens = searchString.toLowerCase().split(/ +/);
  const names = [tableName.toLowerCase()];
  if (environment.requireBoolean("SOURCES_SEARCH_CATALOGS_AND_SCHEMAS")) {
    names.push(schemaName.toLowerCase(), catalogName.toLowerCase());
  }
  return every(searchTokens, (token) => find(names, (name) => name.includes(token)));
}

function toggleSelected(table: SystemTable) {
  sourceBrowserStore.toggleSelected(table);
}

function toggleGroupCollapsed(group: string) {
  sourceBrowserStore.toggleGroupCollapsed(group);
}

const isSelected = (table: SystemTable) => sourceBrowserStore.isSelected(table);

const isGroupExpanded = (group: string) => sourceBrowserStore.collapsedGroups.includes(group);

function rowCount(rowCount: number): string | undefined {
  if (!rowCount) {
    return undefined;
  }
  return `${rowCount.toLocaleString()} ${pluralize("row", rowCount)}`;
}

onMounted(function () {
  if (sourceBrowserStore.catalogs.status === AsyncStatus.NotStarted) {
    sourceBrowserStore.loadSystemTables();
  }
});

function refreshTables() {
  sourceBrowserStore.catalogs.status = AsyncStatus.NotStarted;
  sourceBrowserStore.loadSystemTables(true);
}
</script>
