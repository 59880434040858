<template>
  <label>
    <div class="checkbox">
      <div class="box">
        <input type="checkbox" v-model="value" />
      </div>
      <div class="label">
        {{ label }}
      </div>
    </div>
  </label>
</template>

<style lang="scss" scoped>
.checkbox {
  display: flex;
  margin-top: 0 !important;
  align-items: center;
  padding: 0 6px;

  .label {
    margin-left: 2px;
  }
  .box {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    padding: 4px;
    &:hover {
      background-color: var(--Canvas);
    }
  }
}

input {
  margin-right: $normal-margin;
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  border: 1px solid $gray12;
  cursor: pointer;
  position: relative;
  padding: 0;
  color: $gray12;
  margin: 4px;
  &:hover {
    background-color: var(--Canvas);
  }
}
input[type="checkbox"]:checked {
  color: $gray3;
  background-color: $orange;
  border-color: $orange;
}
input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 5px;
  width: 4px;
  height: 8px;
  border: solid $gray3;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
</style>

<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps<{ label: string; modelValue: boolean; ctOrange?: boolean }>();
const emit = defineEmits(["update:modelValue"]);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
</script>
