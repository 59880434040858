<template>
  <Chart :spec="spec" @select="handleSelect" />
</template>

<script lang="ts" setup>
import { FetchNPropertySet } from "@/common/lib/query";
import { PieChartVisualization, singleValuesOrNull } from "@/reader/lib/visualization";
import { ComputedRef, computed, toRefs } from "vue";
import * as vega from "vega";
import Chart from "@/common/components/Chart.vue";
import { GraphValue, stringifyValue } from "@/common/lib/value";

// XXX Nearly all of this is copy-pasted from DiscreteDistribution.

const props = defineProps<{
  visualization: PieChartVisualization;
  results: FetchNPropertySet[];
  width?: number;
  height?: number;
}>();
const { visualization, results, width, height } = toRefs(props);

const emit = defineEmits<{ select: [alias: string, value: GraphValue | null] }>();

interface Datum {
  index: number;
  category: GraphValue | null;
  categoryId: string;
  categoryName: [string, string];
  value: number;
  tooltip: Record<string, string>;
}

const data = computed(() =>
  results.value.map(function (row, index): Datum {
    const config = visualization.value.config;
    const values = singleValuesOrNull(row);
    const category = values[config.category];
    const categoryId = category ? stringifyValue(category) : "";
    const categoryName = config.category_name ? values[config.category_name] : category;
    const categoryNameStr = categoryName ? stringifyValue(categoryName) : "-";
    const value = Number(values[config.value]?.value);
    return {
      index,
      category,
      categoryId,
      categoryName: [categoryNameStr, categoryId],
      value: Number(value),
      tooltip: { [categoryNameStr]: String(value) },
    };
    // Why does categoryName include the id as well? Because both the domain and
    // range of the scale mapping categoryId to categoryName must be unique, so
    // without this, duplicate categoryNames cause brokenness
  })
);

const spec: ComputedRef<vega.Spec> = computed(function () {
  const chartWidth = (width.value ?? 370) - 10;
  const chartHeight = (height.value ?? 300) - 10;
  const outerRadius = Math.min(chartWidth - 100, chartHeight) / 2; // 100 is a carve-out for the legend
  const innerRadius = outerRadius * 0.66;
  const spec: vega.Spec = {
    width: chartWidth,
    height: chartHeight,
    padding: 5,
    autosize: "fit",
    data: [
      {
        name: "table",
        values: data.value,
        transform: [
          {
            type: "pie",
            field: "value",
          },
        ],
      },
    ],
    signals: [
      {
        name: "selection",
        value: null,
        on: [{ events: "@arc:click", update: "datum.category" }],
      },
    ],
    scales: [
      {
        name: "color",
        type: "ordinal",
        domain: { data: "table", field: "categoryId" },
        range: { scheme: "category20" },
      },
      {
        name: "category_names",
        type: "ordinal",
        domain: { data: "table", field: "categoryId" },
        range: { data: "table", field: "categoryName" },
      },
    ],
    marks: [
      {
        name: "arc",
        type: "arc",
        from: { data: "table" },
        encode: {
          enter: {
            fill: { scale: "color", field: "categoryId" },
            x: { value: outerRadius },
            y: { value: chartHeight / 2 },
            startAngle: { field: "startAngle" },
            endAngle: { field: "endAngle" },
            cursor: { value: "pointer" },
            tooltip: { signal: "datum.tooltip" },
          },
          update: {
            innerRadius: { value: innerRadius },
            outerRadius: { value: outerRadius },
          },
          hover: {
            innerRadius: { value: innerRadius - 5 },
            outerRadius: { value: outerRadius + 5 },
          },
        },
      },
    ],
    legends: [
      {
        fill: "color",
        symbolLimit: 10,
        labelLimit: chartWidth - outerRadius * 2 - 40,
        encode: {
          symbols: {
            name: "legendSymbol",
            enter: {
              strokeWidth: { value: 2 },
              size: { value: 200 },
            },
          },
          labels: {
            name: "legendLabel",
            update: {
              text: { signal: "scale('category_names', datum.value)[0]" },
              fontSize: { value: 12 },
            },
          },
        },
      },
    ],
  };
  return spec;
});

function handleSelect(category: unknown) {
  emit("select", visualization.value.config.category, category as GraphValue | null);
}
</script>
