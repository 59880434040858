<template>
  <div
    class="mx-5 my-4 flex h-20 cursor-pointer items-center rounded-full p-4 hover:bg-gray-200 dark:text-white dark:hover:bg-black"
  >
    <IconButton name="close" color="orange" size="l" :highlighted="true" @click="removeColumn()" />
    <Dropdown placement="right" class="flex basis-full items-center">
      <template #popper>
        <Menu :items="menuItems" v-close-popper />
      </template>
      <div class="mx-5 basis-full">{{ label }}</div>
      <IconButton name="chevron-down" size="xs" />
    </Dropdown>
  </div>
</template>

<script setup lang="ts">
import IconButton from "@/common/components/IconButton.vue";
import Menu from "@/common/components/Menu.vue";
import { propertyName } from "@/common/lib/derived";
import { exploreMenu, ExploreMenuSubject } from "@/reader/lib/exploreMenus";
import { useExploreStore } from "@/reader/stores/explore";
import { Dropdown } from "floating-vue";
import { computed, toRefs } from "vue";

const props = defineProps<{
  alias: string;
}>();
const { alias } = toRefs(props);

const exploreStore = useExploreStore();

const column = () => exploreStore.columnByAlias(alias.value)!;

const label = computed(() => propertyName(column().property_type, undefined, column().displayName));
const menuItems = computed(() =>
  exploreMenu(ExploreMenuSubject.Column, {
    columnAlias: alias.value,
  })
);

function removeColumn() {
  exploreStore.removeColumn(alias.value);
}
</script>
