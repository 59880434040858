<template>
  <Teleport to="#modal-container" v-if="failure">
    <div class="modal">
      <div class="shade"></div>
      <div class="content">
        <div class="dialog" style="z-index: 3000">
          <div class="failure">
            <div class="header">
              <div class="title"><Icon name="error" size="xl" />{{ failure.title }}</div>
              <div class="description max-h-[300px] overflow-y-scroll">{{ failure.message }}</div>
              <div
                v-if="environment.requireBoolean('FAILURE_DIALOG_SHOW_USER_REPORTING')"
                class="description"
              >
                We track these errors automatically, but please feel free to add more information
                about what caused the error.
              </div>
              <div class="buttons">
                <TextButton
                  v-if="environment.requireBoolean('FAILURE_DIALOG_SHOW_USER_REPORTING')"
                  label="Add Details"
                  icon="settings_applications"
                  :selected="showBugReporter"
                  @click="showBugReporter = !showBugReporter"
                />
                <TextButton
                  v-if="environment.requireBoolean('FAILURE_DIALOG_SHOW_ERROR_DETAILS')"
                  label="View Details"
                  icon="settings_applications"
                  :selected="showErrorDetails"
                  @click="showErrorDetails = !showErrorDetails"
                />
                <div class="buttons">
                  <TextButton
                    label="Undo"
                    @click="handleUndo"
                    v-if="!failure.hideUndo && canUndo()"
                  />
                  <TextButton label="Ok" @click="close" />
                </div>
              </div>
            </div>
            <template v-if="environment.requireBoolean('FAILURE_DIALOG_SHOW_ERROR_DETAILS')">
              <div v-if="showErrorDetails">
                <textarea class="details" ref="copyableText" v-model="details"></textarea>
                <div class="buttons">
                  <TextButton label="Copy Bug Report" @click="copyReport()" />
                </div>
              </div>
            </template>
            <template v-if="environment.requireBoolean('FAILURE_DIALOG_SHOW_USER_REPORTING')">
              <div v-if="showBugReporter && failure.eventId">
                <Textbox
                  :textarea="true"
                  min-height="100px"
                  label="Details"
                  v-model="userBugReport"
                />
                <div class="buttons">
                  <TextButton
                    label="Submit Bug Report"
                    @click="submitBugReport(failure!.eventId!)"
                    :disabled="userBugReport === ''"
                  />
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
.modal,
.shade,
.content {
  @include full-size;
}

.shade {
  background: $gray0;
  opacity: 0.5;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  background: var(--Action-Bar-Dark);
  border: 1px solid var(--Dark-Gray);
  box-shadow: 0px $thin-margin $thin-margin rgba(0, 0, 0, 0.25);
  padding: $normal-margin;
  max-width: 440px;
  overflow-wrap: break-word;
}
.failure {
  display: flex;
  flex-direction: column;
}

.details {
  flex: 100% 0 1;
  overflow-y: scroll;
  font-family: inherit;
  background: black;
  padding: $normal-margin;
  color: inherit;
  border: none;
  resize: none;
  margin-top: $normal-margin;

  width: 100%;
  height: 300px;
}

.title {
  font-size: 18px;
  font-weight: 700;
  display: flex;
  gap: $normal-margin;
}
.description {
  font-size: 18px;
  margin: $normal-margin 0;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $normal-margin;
  button {
    margin-top: $thin-margin;
  }
}
.details-toggle {
  color: var(--Light-Gray);
  border: 1px solid var(--Medium-Gray);
  border-radius: 3px;
  display: inline-flex;
  padding: $thin-margin;
  justify-content: center;
  align-items: center;
  gap: $thin-margin;
  font-size: 12px;
  font-weight: 400;
  margin-top: $thin-margin;

  $input-border-radius: 3px;
  text-align: center;
  font-size: $button-font-size;

  &-selected {
    border: none;
    background-color: var(--Canvas);
  }
}
</style>

<script lang="ts" setup>
import Icon from "@/common/components/Icon.vue";
import TextButton from "@/common/components/TextButton.vue";
import { computed, Ref, ref } from "vue";
import Textbox from "@/common/components/Textbox.vue";
import { useSentry } from "@/common/monitoring/sentry/sentryStore";
import { environment } from "@/common/environments/environmentLoader";
import { storeToRefs } from "pinia";
import { useFailureStore } from "../stores/failureStore";

const copyableText: Ref<HTMLTextAreaElement | undefined> = ref();
const showErrorDetails = ref(false);
const showBugReporter = ref(false);

const failureStore = useFailureStore();
const { failure, additionalContext, canUndo, undo } = storeToRefs(failureStore);

const userBugReport = ref("");

const details = computed(() => {
  if (failure?.value === undefined) {
    return undefined;
  }
  const components = {
    "Error Type": failure.value.type,
    Description: failure.value.description,
    Endpoint: failure.value.endpoint,
    "Exception Name": failure.value.exceptionName,
    "Exception Message": failure.value.message,
    "Exception Cause": failure.value.exceptionCause,
    "Exception Stack": failure.value.exceptionStack,
    Request: JSON.stringify(failure.value.request, undefined, 2),
    Response: JSON.stringify(failure.value.response, undefined, 2),
  };
  if (additionalContext?.value) {
    Object.assign(components, additionalContext.value());
  }
  return Object.entries(components)
    .filter((entry) => entry[1] !== undefined)
    .map(([key, value]) => `## ${key}\n\n${value}\n\n`)
    .join("");
});

function copyReport() {
  copyableText.value?.select();
  document.execCommand("copy");
}

function submitBugReport(eventId: string) {
  if (failure?.value == undefined) {
    return;
  }
  useSentry().sentry?.captureUserFeedback(failure.value.source, eventId, userBugReport.value);
  close();
}

function handleUndo() {
  undo.value();
  close();
}

function close() {
  failureStore.clearFailure();
}
</script>
