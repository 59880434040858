<template>
  <div class="property-group">
    <div class="type">{{ propTypeLabel }}</div>
    <div class="values">
      <div class="value" v-for="(value, index) in values" :key="index">
        <GraphValueComponent :value="value.formattedValue" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.property-group {
  margin: $normal-margin 0;
}

.type {
  text-transform: uppercase;
  color: $gray8;
}
</style>

<script lang="ts" setup>
import useKnowledge from "@/common/composables/useKnowledge";
import { computed, toRefs } from "vue";
import { PropertyKnowledgeRef } from "@/common/lib/knowledge";
import { ExploreValue } from "@/reader/lib/explore";
import GraphValueComponent from "@/common/components/GraphValue.vue";

const props = defineProps<{ values: ExploreValue[]; propertyType: PropertyKnowledgeRef }>();
const { values, propertyType } = toRefs(props);

const { typeLabel } = useKnowledge();

const propTypeLabel = computed(() => typeLabel(propertyType.value));
</script>
