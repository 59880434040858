<template>
  <v-select
    v-model="model"
    class="select"
    :class="{ 'light-select': !darkMode }"
    :options="options"
    :getOptionLabel="groupByLabel"
    :clearable="false"
    placeholder="Choose a Property"
  />
</template>

<script lang="ts" setup>
import { ExploreGroupBy, rootColumns } from "@/reader/lib/explore";
import { computed, inject, Ref } from "vue";
import vSelect from "vue-select";
import { DarkMode } from "@/common/lib/keys";
import { propertyName } from "@/common/lib/derived";

const darkMode = inject(DarkMode) as Ref<boolean>;
const model = defineModel<ExploreGroupBy | null>({ required: true });

const options = computed(() =>
  rootColumns().map((column) => ({
    property_type: column.property_type,
  }))
);

function groupByLabel(groupBy: ExploreGroupBy) {
  return propertyName(groupBy.property_type, groupBy.neighborhood);
}
</script>
