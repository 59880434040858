import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-f8ca7ecc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  key: 0,
  class: "loading"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { class: "properties" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode($setup["Icon"], {
        name: $setup.iconName,
        color: { dark: 'white', light: 'black' }
      }, null, 8 /* PROPS */, ["name"]),
      _createElementVNode("div", _hoisted_2, _toDisplayString($setup.title), 1 /* TEXT */)
    ]),
    ($setup.queryResults.status === $setup.AsyncStatus.InProgress)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode($setup["Spinner"])
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.queryResults.status === $setup.AsyncStatus.Failed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($setup.queryResults.message), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    ($setup.queryResults.status === $setup.AsyncStatus.Succeeded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.propertyValuesByType, (properties, type) => {
              return (_openBlock(), _createBlock($setup["ValueGroup"], {
                values: properties,
                "property-type": type as PropertyKnowledgeRef,
                key: type
              }, null, 8 /* PROPS */, ["values", "property-type"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.neighborhoods, (neighborhood) => {
            return (_openBlock(), _createBlock($setup["NeighborhoodSummary"], {
              key: neighborhood.key,
              concepts: neighborhood.concepts,
              onNavigate: _cache[0] || (_cache[0] = (address) => $setup.emit('navigate', address)),
              onPivot: ($event: any) => (
        $setup.emit(
          'pivot',
          neighborhood.concepts[0].type,
          neighborhood.pivotFilters,
          neighborhood.pivotPath
        )
      )
            }, null, 8 /* PROPS */, ["concepts", "onPivot"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}