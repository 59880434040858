<template>
  <div
    class="mx-5 my-4 flex h-20 cursor-pointer items-center rounded-full p-4 hover:bg-gray-200 dark:hover:bg-black"
    :class="{ 'dark:text-white': isCurrentColumn, 'text-gray-500': !isCurrentColumn }"
    v-if="!exploreStore.hideUnusedProperties || isCurrentColumn"
  >
    <IconButton
      :name="isCurrentColumn ? 'checkbox-selected' : 'checkbox-empty'"
      :highlighted="isCurrentColumn"
      :color="isCurrentColumn ? 'orange' : undefined"
      :disabled="nonaggregateColumnsProhibited"
      :tooltip="
        nonaggregateColumnsProhibited
          ? 'While Summarizing All, only aggregate columns can be added.'
          : undefined
      "
      size="l"
      @click="toggleBasicColumn()"
    />
    <Dropdown placement="right" class="flex basis-full items-center">
      <template #popper>
        <Menu :items="menuItems" v-close-popper />
      </template>
      <div class="mx-5 basis-full">{{ label }}</div>
      <IconButton name="chevron-down" size="xs" />
    </Dropdown>
  </div>
  <TreeDerivedColumn v-for="alias of derivedAliases" :alias="alias" :key="alias" />
  <Filter v-for="filter of relatedFilters" :filter="filter" :key="filter.alias" />
</template>

<script setup lang="ts">
import IconButton from "@/common/components/IconButton.vue";
import Menu from "@/common/components/Menu.vue";
import { PropertyOpType } from "@/common/lib/derived";
import { PropertyKnowledgeRef } from "@/common/lib/knowledge";
import useKnowledge from "@/common/composables/useKnowledge";
import { buildSimpleColumn, findCurrentColumn } from "@/reader/lib/explore";
import { exploreMenu, ExploreMenuSubject } from "@/reader/lib/exploreMenus";
import { expandTreePath, ExploreTreePath } from "@/reader/lib/exploreTree";
import { useExploreStore } from "@/reader/stores/explore";
import { Dropdown } from "floating-vue";
import { computed, toRefs } from "vue";
import Filter from "../Filter.vue";
import TreeDerivedColumn from "./TreeDerivedColumn.vue";
import { GROUP_BY_ALL } from "@/common/lib/fetchApi";
import { isEqual } from "lodash";

const props = defineProps<{
  conceptPath: ExploreTreePath;
  propertyType: PropertyKnowledgeRef;
  derivedAliases: string[];
}>();
const { propertyType, conceptPath } = toRefs(props);

const exploreStore = useExploreStore();
const { typeLabel } = useKnowledge();

const label = computed(() => typeLabel(propertyType.value));
const isCurrentColumn = computed(() => !!findCurrentColumn(column()));
const menuItems = computed(() =>
  exploreMenu(ExploreMenuSubject.PropertyType, {
    conceptPath: conceptPath.value,
    propertyType: propertyType.value,
  })
);
const nonaggregateColumnsProhibited = computed(() => exploreStore.query!.group_by === GROUP_BY_ALL);

function column(op?: PropertyOpType) {
  return buildSimpleColumn(expandTreePath(conceptPath.value), propertyType.value, op);
}

function toggleBasicColumn(op?: PropertyOpType) {
  const currentColumn = findCurrentColumn(column(op));
  if (currentColumn != null) {
    exploreStore.removeColumn(findCurrentColumn(column(op))!);
  } else {
    exploreStore.addColumn(column(op));
  }
}

const relatedFilters = computed(function () {
  const asColumn = column();
  return exploreStore.query!.filters.filter(
    (f) => isEqual(f.path, asColumn.path) && f.property_type === asColumn.property_type
  );
});
</script>
