<template>
  <div class="mr-1">
    <TreeConcept :path="rootPath" />
  </div>
</template>

<script setup lang="ts">
import { ExploreTreeNode } from "@/reader/lib/exploreTree";
import { useExploreStore } from "@/reader/stores/explore";
import { computed } from "vue";
import TreeConcept from "./TreeConcept.vue";

const exploreStore = useExploreStore();
const rootPath = computed(function (): ExploreTreeNode[] {
  return [
    {
      conceptType: exploreStore.root_concept_type!,
    },
  ];
});
</script>
