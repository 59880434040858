import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "mx-2 my-4 bg-white text-gray-800 dark:bg-black dark:text-gray-200" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "mb-2 flex min-w-[16px]"
}
const _hoisted_4 = { class: "basis-full overflow-hidden whitespace-nowrap" }
const _hoisted_5 = {
  key: 2,
  class: "cursor-default opacity-50"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Menu = _resolveComponent("Menu", true)!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item) => {
      return (_openBlock(), _createBlock($setup["Dropdown"], {
        key: item.key,
        disabled: !item.submenu,
        placement: "right-start",
        style: _normalizeStyle({ 'min-width': $props.minWidth }),
        distance: 10
      }, {
        popper: _withCtx(() => [
          _createVNode(_component_Menu, {
            items: item.submenu!,
            onSelect: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('select')))
          }, null, 8 /* PROPS */, ["items"])
        ]),
        default: _withCtx(() => [
          _createElementVNode("li", {
            onClick: ($event: any) => ($setup.select(item)),
            class: _normalizeClass(["group flex cursor-pointer items-center gap-10 px-10 leading-[25px]", {
            'cursor-default text-gray-500': item.disabled,
            'hover:bg-orange hover:text-white': !item.disabled,
            'border-b border-b-gray-300 dark:border-b-gray-700': item.section,
          }])
          }, [
            ($setup.useIcons)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (item.icon)
                    ? (_openBlock(), _createBlock($setup["Icon"], {
                        key: 0,
                        name: item.icon,
                        size: "l",
                        color: "white"
                      }, null, 8 /* PROPS */, ["name"]))
                    : _createCommentVNode("v-if", true)
                ]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_4, _toDisplayString(item.label), 1 /* TEXT */),
            (item.submenu)
              ? (_openBlock(), _createBlock($setup["Icon"], {
                  key: 1,
                  name: "submenu",
                  size: "xxs",
                  class: "ml-5 flex-shrink-0 flex-grow"
                }))
              : _createCommentVNode("v-if", true),
            (item.shortcut)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(item.shortcut), 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ], 10 /* CLASS, PROPS */, _hoisted_2)
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["disabled", "style"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}