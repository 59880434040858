import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6983138e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pill-container" }
const _hoisted_2 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      style: _normalizeStyle({ color: $props.color })
    }, "⬤", 4 /* STYLE */),
    _createElementVNode("div", _hoisted_2, _toDisplayString($props.label), 1 /* TEXT */)
  ]))
}