import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1b57068a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "neighborhood" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "label" }
const _hoisted_4 = { class: "neighbors" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["Icon"], {
        name: $setup.conceptIconName,
        color: { dark: 'white', light: 'black' }
      }, null, 8 /* PROPS */, ["name"]),
      _createElementVNode("div", _hoisted_3, _toDisplayString($setup.conceptTypeLabel), 1 /* TEXT */),
      _createVNode($setup["IconButton"], {
        name: "table",
        size: "l",
        tooltip: "View all in table",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('pivot')))
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.neighbors, (neighbor) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["neighbor", { '-with-link': neighbor.address != null }]),
          key: neighbor.key,
          onClick: ($event: any) => ($setup.clickLink(neighbor.address))
        }, _toDisplayString(neighbor.title), 11 /* TEXT, CLASS, PROPS */, _hoisted_5))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}