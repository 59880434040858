<template>
  <div class="header">
    <Icon :name="iconName" :color="{ dark: 'white', light: 'black' }" />
    <div class="title">{{ title }}</div>
  </div>

  <div class="loading" v-if="queryResults.status === AsyncStatus.InProgress">
    <Spinner />
  </div>

  <div v-if="queryResults.status === AsyncStatus.Failed">{{ queryResults.message }}</div>
  <div v-if="queryResults.status === AsyncStatus.Succeeded">
    <div class="properties">
      <ValueGroup
        v-for="(properties, type) of propertyValuesByType"
        :values="properties"
        :property-type="type as PropertyKnowledgeRef"
        :key="type"
      />
    </div>

    <NeighborhoodSummary
      v-for="neighborhood of neighborhoods"
      :key="neighborhood.key"
      :concepts="neighborhood.concepts"
      @navigate="(address) => emit('navigate', address)"
      @pivot="
        emit(
          'pivot',
          neighborhood.concepts[0].type,
          neighborhood.pivotFilters,
          neighborhood.pivotPath
        )
      "
    />
  </div>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  margin-bottom: $normal-margin;

  .title {
    flex: 0 1 100%;
    margin: 0 $thin-margin;
    font-size: 20px;
    font-weight: bold;
  }
}

.loading {
  display: flex;
  padding: $wide-margin;
  justify-content: center;
}
</style>

<script lang="ts" setup>
import Icon from "@/common/components/Icon.vue";
import Spinner from "@/common/components/Spinner.vue";
import useKnowledge from "@/common/composables/useKnowledge";
import { AsyncStatus, asyncHasValue } from "@/common/lib/async";
import { ConceptKnowledgeRef, PropertyKnowledgeRef } from "@/common/lib/knowledge";
import { Neighborhood, PropertyFilter } from "@/common/lib/query";
import useQuery from "@/reader/composables/useQuery";
import { ConceptAddress, buildConceptQuery } from "@/reader/lib/concept";
import { formatValue } from "@/reader/lib/explore";
import { groupBy, mapValues } from "lodash";
import { computed, toRefs } from "vue";
import NeighborhoodSummary from "./NeighborhoodSummary.vue";
import ValueGroup from "./ValueGroup.vue";
import { useAppStore } from "@/reader/stores/app";

const props = defineProps<{ address: ConceptAddress }>();
const { address } = toRefs(props);

const emit = defineEmits<{
  (e: "navigate", address: ConceptAddress): void;
  (
    e: "pivot",
    conceptType: ConceptKnowledgeRef,
    filters: PropertyFilter[],
    neighborhood: Neighborhood
  ): void;
}>();

const { getConceptIconName, conceptTitle, typeLabel } = useKnowledge();
const appStore = useAppStore();

const { queryResults, rootConcept, neighborhoods } = useQuery(
  appStore.module!,
  () => buildConceptQuery(address.value),
  () => appStore.map
);

const title = computed(function () {
  const conceptLabel = typeLabel(address.value.conceptType);
  if (asyncHasValue(queryResults.value)) {
    return conceptTitle(rootConcept()) ?? conceptLabel;
  } else {
    return `Loading ${conceptLabel}...`;
  }
});

const propertyValuesByType = computed(function () {
  const grouped = groupBy(rootConcept().properties || [], "type");
  return mapValues(grouped, (props, type) =>
    props.map((prop) => formatValue(type as PropertyKnowledgeRef, prop.value!))
  );
});

const iconName = computed(() => getConceptIconName(address.value.conceptType));
</script>
