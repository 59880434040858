<template>
  <Dropdown placement="auto">
    <template #popper><Menu :items="menuItems" v-close-popper /></template>
    <div
      class="interactive-value"
      :class="{
        '-with-bar': hasBarChart,
        '-quantitative': quantitative,
        '-with-concept-page': hasConceptPage,
      }"
    >
      <GraphValueComponent :value="value.formattedValue" />
      <div class="bar" v-if="hasBarChart">
        <MiniBar :column="column!" :value="value.originalValue" />
      </div>
    </div>
  </Dropdown>
</template>

<style lang="scss" scoped>
.interactive-value {
  display: flex;
  align-items: baseline;
  cursor: pointer;

  &.-quantitative {
    justify-content: end;
  }

  &.-with-concept-page {
    text-decoration: underline;
  }
}

.bar {
  padding-left: $normal-margin;
}
</style>

<script setup lang="ts">
import GraphValueComponent from "@/common/components/GraphValue.vue";
import Menu from "@/common/components/Menu.vue";
import { asyncValue } from "@/common/lib/async";
import { Dropdown } from "floating-vue";
import { computed, toRefs } from "vue";
import { ExploreColumn, ExploreValue, neighborhoodConceptType } from "../../lib/explore";
import { propertyValueMenu } from "../../lib/exploreMenus";
import { useExploreStore } from "../../stores/explore";
import MiniBar from "../MiniBar.vue";
import { isString } from "lodash";
import { isConceptPageLinkable } from "../../lib/concept";

const props = defineProps<{ value: ExploreValue; column?: ExploreColumn; quantitative: boolean }>();
const { column, value } = toRefs(props);

const exploreStore = useExploreStore();

const menuItems = computed(() =>
  propertyValueMenu(value.value.originalValue, value.value.formattedValue, column?.value)
);

const hasBarChart = computed(
  () => column?.value != null && asyncValue(exploreStore.columnStats)![column.value.alias] != null
);

const hasConceptPage = computed(function () {
  if (column.value == null) return false;
  if (!isString(column.value.property_type)) return false;
  return isConceptPageLinkable(
    neighborhoodConceptType(column.value.neighborhood),
    column.value.property_type
  );
});
</script>
