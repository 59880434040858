import { User } from "../lib/user";
import { AuthProvider } from "./authProvider";

/**
 * No-op implemented of AuthProvider.
 * Turns off frontend auth completely.
 */
export class NoopAuthProvider implements AuthProvider {
  // Allow acess to all routes.
  public authGuard(): boolean {
    return true;
  }

  public getAccessToken(): Promise<string> {
    return Promise.reject(new Error("NoopAuthProvider getAccessToken not implemented"));
  }

  public loginWithRedirect(): Promise<void> {
    return Promise.reject(new Error("NoopAuthProvider loginWithRedirect not implemented"));
  }

  public logout(): Promise<void> {
    return Promise.reject(new Error("NoopAuthProvider logout not implemented"));
  }

  public async waitAuthenticated(): Promise<boolean> {
    return await this.isAuthenticated;
  }

  public async handleAuthenticationError(error: Error): Promise<void> {
    // We can't handle, so just rethrow.
    throw await error;
  }

  public user: User | undefined = undefined;

  public isLoading = false;

  public isEnabled = false;

  public isAuthenticated = false;
}
