/**
 * Wrapper to load environment variables set in .env and equivalents.
 */
class Environment {
  /**
   * Get a variable if it exists.
   * @param key name of environment variable, without the VUE_APP prefix
   * @param defaultValue optional default value, defaults to undefined
   * @returns value of environment variable as a string, or undefined if it doesn't exist
   */
  public get(key: string, defaultValue: string): string;
  public get(key: string): string | undefined;
  public get(key: string, defaultValue: string | undefined = undefined): string | undefined {
    const envName = "VUE_APP_" + key;
    return process.env[envName] || defaultValue;
  }

  /**
   * Get a variable, failing if it doesn't exist.
   * @param key name of environment variable, without the VUE_APP prefix
   * @returns value of environment variable as a string
   * @throws Error if variable doesn't exist
   */
  public require(key: string): string {
    const value = this.get(key);
    if (!value) {
      throw new Error("Could not find required environment variable: " + key);
    }
    return value;
  }

  /**
   * Get a boolean variable, failing if it doesn't exist.
   * True is "true", "TRUE", "tRuE"; anything else is false.
   * @param key name of environment variable, without the VUE_APP prefix
   * @returns value of environment variable as a boolean
   * @throws Error if variable doesn't exist
   */
  public requireBoolean(key: string): boolean {
    const value = this.require(key);
    return value.toLowerCase() === "true";
  }

  /**
   * Get a numeric variable, failing if it doesn't exist or is not parsable.
   * @param key name of environment variable, without the VUE_APP prefix
   * @returns value of environment variable as a number
   * @throws Error if variable doesn't exist
   */
  public requireNumber(key: string): number {
    const value = this.require(key);
    return Number(value);
  }
}

/**
 * Global environment
 */
export const environment = new Environment();
