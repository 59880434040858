import useKnowledge from "@/common/composables/useKnowledge";
import {
  propertyName,
  propertyValueType,
  validDerivedPropertyTermType,
} from "@/common/lib/derived";
import {
  FILTER_TYPES_FOR_PROPERTY_VALUE_TYPE,
  FilterType,
  GROUP_BY_ALL,
} from "@/common/lib/fetchApi";
import { GraphCompoundValue } from "@/common/lib/graph";
import { PropertyKnowledgeRef } from "@/common/lib/knowledge";
import { MenuItem } from "@/common/lib/menus";
import { QueryColumn, QueryFilter } from "@/common/lib/query";
import { GraphValue, isValue, stringifyValue } from "@/common/lib/value";
import { useExploreStore } from "@/reader/stores/explore";
import { isString, omit } from "lodash";
import pluralize from "pluralize";
import { uniquelyIdentifiesConcept } from "./concept";
import {
  availablePivots,
  buildCountColumn,
  buildSimpleColumn,
  filterWithDefaults,
  findCurrentColumn,
  pathConceptType,
  SIMPLE_COLUMN_OPS,
} from "./explore";
import { expandTreePath, ExploreTreePath } from "./exploreTree";

export enum ExploreMenuSubject {
  PropertyType,
  ConceptType,
  Column,
}

export interface ExploreMenuContext {
  conceptPath?: ExploreTreePath;
  propertyType?: PropertyKnowledgeRef;
  columnAlias?: string;
}

export function exploreMenu(subjectType: ExploreMenuSubject, context: ExploreMenuContext) {
  const items: MenuItem[] = [];
  switch (subjectType) {
    case ExploreMenuSubject.PropertyType: {
      items.push(...propertyTypeMenu(context.conceptPath!, context.propertyType!));
      // If the non-aggregated column already exists, include a column menu for it
      const basicColumn = buildSimpleColumn(context.conceptPath!, context.propertyType!);
      const basicColumnAlias = findCurrentColumn(basicColumn);
      if (basicColumnAlias != null) {
        items.push(...columnMenu(basicColumnAlias));
      }
      break;
    }

    case ExploreMenuSubject.ConceptType:
      items.push(...conceptTypeMenu(context.conceptPath!));
      break;

    case ExploreMenuSubject.Column:
      items.push(...columnMenu(context.columnAlias!));
      break;
  }
  return items;
}

function conceptTypeMenu(treePath: ExploreTreePath) {
  const path = expandTreePath(treePath);
  const exploreStore = useExploreStore();
  const items: MenuItem[] = [];
  if (path != null || exploreStore.query!.group_by) {
    const column = buildCountColumn(path);
    if (findCurrentColumn(column) == null) {
      items.push({
        key: "add-concept-count",
        label: "Add Count",
        action: () => exploreStore.addColumn(column),
      });
    }
  }
  if (path == null) {
    items.push({
      key: "summarize-all",
      label: "Summarize All",
      action: () => exploreStore.setGroupBy(GROUP_BY_ALL),
    });
  }
  items.push({
    key: "add-calculation",
    label: "Add Calculation",
    action: () => (exploreStore.creatingCalculation = treePath),
  });
  return items;
}

function propertyTypeMenu(treePath: ExploreTreePath, propertyType: PropertyKnowledgeRef) {
  const exploreStore = useExploreStore();
  const exploreQuery = exploreStore.query!;
  const path = expandTreePath(treePath);
  const items: MenuItem[] = [];
  const valueType = propertyValueType(propertyType);
  const column = buildSimpleColumn(path, propertyType);
  for (const op of [undefined, ...SIMPLE_COLUMN_OPS]) {
    const opCol = buildSimpleColumn(path, propertyType, op);
    const name = propertyName(opCol.property_type);
    if (
      findCurrentColumn(opCol) == null &&
      (op == null || validDerivedPropertyTermType(op, valueType)) &&
      (op != null || exploreQuery.group_by !== GROUP_BY_ALL)
    ) {
      items.push({
        key: `add-${op || "basic"}`,
        label: `Add ${name} Column`,
        action: () => exploreStore.addColumn(opCol),
      });
    }
  }
  const validFilterTypes = FILTER_TYPES_FOR_PROPERTY_VALUE_TYPE[valueType];
  let filterType: FilterType | undefined = undefined;
  if (validFilterTypes.includes(FilterType.Range)) {
    filterType = FilterType.Range;
  } else if (validFilterTypes.includes(FilterType.Text)) {
    filterType = FilterType.Text;
  } else if (validFilterTypes.includes(FilterType.Equality)) {
    filterType = FilterType.Equality;
  }
  if (filterType !== undefined) {
    items.push({
      key: "filter",
      label: "Add Filter",
      action: () =>
        exploreStore.addFilter(
          filterWithDefaults({
            type: filterType,
            property_type: propertyType,
            path: column.path,
          })
        ),
    });
  }
  if (validFilterTypes.includes(FilterType.Exists)) {
    items.push({
      key: "filter-exists",
      label: "Add Exists Filter",
      action: () =>
        exploreStore.addFilter(
          filterWithDefaults({
            type: FilterType.Exists,
            property_type: propertyType,
            path: column.path,
          })
        ),
    });
  }
  items.push({
    key: "group",
    label: "Group by",
    action: function () {
      const newGroupBy = { property_type: propertyType, path: column.path };
      if (exploreQuery.group_by === GROUP_BY_ALL) {
        exploreStore.setGroupBy([newGroupBy]);
      } else {
        exploreStore.setGroupBy([...exploreQuery.group_by, newGroupBy]);
      }
    },
  });
  return items;
}

function columnMenu(columnAlias: string): MenuItem[] {
  const exploreStore = useExploreStore();
  return [
    {
      key: "sort-asc",
      label: "Sort Ascending",
      action: () => exploreStore.setOrderBy([{ on: columnAlias, asc: true }]),
    },
    {
      key: "sort-desc",
      label: "Sort Descending",
      action: () => exploreStore.setOrderBy([{ on: columnAlias, asc: false }]),
    },
    {
      key: "remove",
      label: "Remove Column",
      action: () => exploreStore.removeColumn(columnAlias),
    },
  ];
}

export function propertyValueMenu(
  value: GraphValue | GraphCompoundValue,
  formattedValue: GraphValue,
  columnDef?: QueryColumn
) {
  const { typeLabel } = useKnowledge();
  const exploreStore = useExploreStore();
  const items: MenuItem[] = [];
  if (columnDef !== undefined && isString(columnDef.property_type) && isValue(value)) {
    const propertyType = columnDef.property_type as PropertyKnowledgeRef;
    // Can we construct a ConceptAddress from this?
    const conceptType = pathConceptType(columnDef.path);
    if (uniquelyIdentifiesConcept(conceptType, propertyType)) {
      items.push({
        key: "concept-page",
        label: `View ${typeLabel(conceptType)}`,
        action: () =>
          exploreStore.showConceptPage({
            conceptType,
            keys: { [propertyType]: value as GraphValue },
          }),
      });
    }
    // Add filter(s)
    const colName = propertyName(columnDef.property_type);
    const baseFilter = {
      property_type: propertyType,
      path: columnDef.path,
    };
    let filter: QueryFilter | undefined;
    const validFilterTypes = FILTER_TYPES_FOR_PROPERTY_VALUE_TYPE[(value as GraphValue)._type];
    if (validFilterTypes.includes(FilterType.Range)) {
      filter = filterWithDefaults({
        ...baseFilter,
        type: FilterType.Range,
        values: [
          {
            lte: value,
            gte: value,
          },
        ],
      });
    } else if (validFilterTypes.includes(FilterType.Equality)) {
      filter = filterWithDefaults({
        ...baseFilter,
        type: FilterType.Equality,
        values: [{ value }],
      });
    }
    if (filter !== undefined) {
      items.push({
        key: "filter",
        label: `Filter: Show only this ${colName}`,
        action: () => exploreStore.addFilter(filter),
      });
      items.push({
        key: "filter-negated",
        label: `Filter: Don't show this ${colName}`,
        action: () => exploreStore.addFilter({ ...filter, negated: true }),
      });
      if (filter.type === FilterType.Range) {
        items.push({
          key: "filter-lte",
          label: `Filter: Show this ${colName} or higher`,
          action: () =>
            exploreStore.addFilter({ ...filter, values: [omit(filter.values[0], "lte")] }),
        });
        items.push({
          key: "filter-gte",
          label: `Filter: Show this ${colName} or lower`,
          action: () =>
            exploreStore.addFilter({ ...filter, values: [omit(filter.values[0], "gte")] }),
        });
      }
      // Add pivots
      for (const pivot of availablePivots(columnDef)) {
        items.push({
          key: `pivot-${pivot.to}`,
          label: `Pivot to ${pluralize(typeLabel(pivot.to))} with this ${colName}`,
          action: () => exploreStore.pivot(pivot.to, { ...filter, path: pivot.path }),
        });
      }
    }
    items.push({
      key: "copy",
      label: "Copy value",
      action: () => navigator.clipboard.writeText(stringifyValue(formattedValue)),
    });
  }
  return items;
}
