import { AuthProvider } from "@/common/auth/authProvider";
import { useAuthProvider } from "@/common/auth/authStore";
import { BackendUserError, FailureOptions, FailureType } from "@/common/lib/failure";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { backendFailure, FailureMessage } from "../stores/failureStore";

/* eslint-disable @typescript-eslint/no-explicit-any */
/* We are wrapping an API that specifies `any` */

/**
 * Simple drop-in wrapper around axios to handle auth.
 */
export class Http {
  private auth?: AuthProvider;

  public async get(
    url: string,
    config?: AxiosRequestConfig,
    failureOptions?: FailureOptions
  ): Promise<AxiosResponse<any>> {
    config = await this.updateConfig(config);
    return axios.get(url, config).catch((error) => this.handleError(error, failureOptions));
  }

  public async post(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
    failureOptions?: FailureOptions
  ): Promise<AxiosResponse<any>> {
    config = await this.updateConfig(config);
    return axios.post(url, data, config).catch((error) => this.handleError(error, failureOptions));
  }

  public async put(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
    failureOptions?: FailureOptions
  ): Promise<AxiosResponse<any>> {
    config = await this.updateConfig(config);
    return axios.put(url, data, config).catch((error) => this.handleError(error, failureOptions));
  }

  public async patch(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
    failureOptions?: FailureOptions
  ): Promise<AxiosResponse<any>> {
    config = await this.updateConfig(config);
    return axios.patch(url, data, config).catch((error) => this.handleError(error, failureOptions));
  }

  public async delete(
    url: string,
    config?: AxiosRequestConfig,
    failureOptions?: FailureOptions
  ): Promise<AxiosResponse<any>> {
    config = await this.updateConfig(config);
    return axios.delete(url, config).catch((error) => this.handleError(error, failureOptions));
  }

  private async updateConfig(config?: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    config ||= {};
    if (!this.auth) {
      this.auth = useAuthProvider();
    }
    if (!this.auth.isEnabled) {
      return config;
    }
    await this.auth.waitAuthenticated();
    // TODO: handle failure if not authenticated
    const accessToken = await this.auth.getAccessToken();
    return {
      ...config,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
  }

  private handleError(error: AxiosError<any, unknown>, failureOptions?: FailureOptions): never {
    if (error.response?.data?.error === "KnowledgeValidationException") {
      throw new BackendUserError(error.response?.data?.cause, error);
    }
    throw new BackendError(
      failureOptions?.type || FailureType.Api,
      failureOptions?.description || "API Error",
      error
    );
  }
}

export const httpClient = new Http();

export function isAxiosError<T = any>(error: unknown): error is AxiosError<T, any> {
  return (error as AxiosError).isAxiosError;
}

export class BackendError extends Error {
  public failure?: FailureMessage;

  constructor(
    public type: FailureType,
    description: string,
    error: Error | undefined
  ) {
    super(description, { cause: error });
    this.failure = backendFailure({ type, description, error });
  }
}
