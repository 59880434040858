import { GraphConcept, isValidProperty, stringifyProperty } from "@/common/lib/graph";
import {
  COMPOSITE_PROPERTY_VALUE_TYPE,
  ConceptType,
  GeneratorOutput,
  GeneratorType,
  KnowledgeItem,
  knowledgeItemId,
  KnowledgeType,
  ParserParam,
  ParserType,
  PropertyComponentType,
  PropertyType,
  RECORD_CONCEPT_TYPE,
} from "@/common/lib/knowledge";
import { GraphValueType } from "@/common/lib/value";
import { fromPairs, isEmpty, pick, truncate } from "lodash";
import { useKnowledgeStore } from "../stores/knowledgeStore";

export default function useKnowledge() {
  const knowledgeStore = useKnowledgeStore();
  const knowledgeItems = () => knowledgeStore.combinedKnowledge;

  function getKnowledgeItem(id: string): KnowledgeItem;
  function getKnowledgeItem(id?: string): KnowledgeItem | undefined;
  function getKnowledgeItem(id?: string): KnowledgeItem | undefined {
    if (!id) {
      return undefined;
    }
    const item = knowledgeItems()[id];
    if (item == null) {
      throw new Error(`Failed to find knowledge item with ID ${id}`);
    }
    return item as KnowledgeItem;
  }

  function maybeGetKnowledgeItem(id?: string): KnowledgeItem | undefined {
    if (!id) {
      return undefined;
    }
    const item = knowledgeItems()[id];
    return item;
  }

  function getConceptIconName(conceptId?: string): string {
    if (!conceptId) {
      throw new Error("conceptId not provided");
    }
    const concept = getKnowledgeItem(conceptId) as ConceptType | undefined;
    if (!concept?.icon_name) {
      throw new Error(`No icon found for concept: ${conceptId}`);
    }
    return concept.icon_name;
  }

  function getKnowledgeItemsOfType(type: KnowledgeType) {
    return Object.values(knowledgeItems()).filter((item) => item.type === type);
  }

  function typeLookup(type: string, key: string) {
    const item: KnowledgeItem = getKnowledgeItem(type);
    return (item as unknown as { [key: string]: unknown })[key];
  }

  function typeLabel(type: string) {
    return typeLookup(type, "label") as string;
  }

  /**
   * Lookup the label for a type by ID, falling back to the ID if it isn't found.
   * @param type knowledge ID
   * @returns label for type, or passed-in ID if type not found
   */
  function typeLabelOrDefault(type: string): string {
    const item = maybeGetKnowledgeItem(type);
    return item?.label || type;
  }

  function typeLabelWithAdHocParent(propTypeId: string) {
    const label = typeLabel(propTypeId);
    if (isLocalType(propTypeId)) {
      const propType = getKnowledgeItem(propTypeId);
      const parentLabel = typeLabel(propType.parent as string);
      return `"${label}" (${parentLabel})`;
    } else {
      return label;
    }
  }

  function isLocalType(type: string) {
    return getKnowledgeItem(type).adhoc === true;
  }

  function propertyComponentLabel(propType: string, componentType: string) {
    const prop = getKnowledgeItem(propType) as PropertyType;
    return (prop.components?.[componentType] as PropertyComponentType).label;
  }

  function propertyAndComponentLabel(propType: string, componentType?: string) {
    // this method returns a label taking in consideration of both property_type and property_component
    if (componentType) {
      return `${typeLabel(propType)}: ${propertyComponentLabel(propType, componentType)}`;
    } else {
      return `${typeLabel(propType)}`;
    }
  }

  function conceptTitle(concept: GraphConcept) {
    const type = getKnowledgeItem(concept.type) as ConceptType;
    for (const propTypeId of type.title_properties) {
      const firstProp = (concept.properties || []).find(
        (prop) => isAncestorOf(prop.type, propTypeId) && isValidProperty(prop)
      );
      if (firstProp != null) {
        const propStr = stringifyProperty(firstProp);
        if (!isEmpty(propStr)) return truncate(propStr, { length: 100 });
      }
    }
    return null;
  }

  function validParsers(propType: string): string[] {
    // Collect parents of this prop type that share its parsers
    const relatedProps: string[] = [];
    let current: string | undefined = propType;
    while (current != null) {
      relatedProps.push(current);
      const currentItem = getKnowledgeItem(current) as PropertyType;
      current = currentItem.parent;
    }
    return getKnowledgeItemsOfType(KnowledgeType.Parser)
      .filter((parser) => relatedProps.includes((parser as ParserType).property_type))
      .map(knowledgeItemId);
  }

  // Gets params of a parser type, or constructs params of the implicit parser
  function parserParams(
    propertyType: string,
    parserType: string | undefined
  ): Record<string, ParserParam> {
    if (parserType) return (getKnowledgeItem(parserType) as ParserType).params;
    const propTypeDef = getKnowledgeItem(propertyType) as PropertyType;
    if (propTypeDef.value_type === COMPOSITE_PROPERTY_VALUE_TYPE) {
      return fromPairs(
        Object.entries(propTypeDef.components || {}).map(([componentKey, component]) => [
          componentKey,
          { ...pick(component, ["value_type", "label"]), required: false },
        ])
      );
    } else {
      return {
        value: {
          value_type: propTypeDef.value_type as GraphValueType,
          label: "Value",
          required: true,
        },
      };
    }
  }

  function validGenerators(propType: string): string[] {
    // Collect parents of this prop type that share its generators
    const relatedProps: string[] = [];
    let current: string | undefined = propType;
    while (current != null) {
      relatedProps.push(current);
      const currentItem = getKnowledgeItem(current) as PropertyType;
      current = currentItem.parent;
    }
    return getKnowledgeItemsOfType(KnowledgeType.Generator)
      .filter((generator) => relatedProps.includes((generator as GeneratorType).property_type))
      .map(knowledgeItemId);
  }

  // Gets params of a parser type, or constructs params of the implicit parser
  function generatorOutputs(
    propertyType: string,
    generatorType: string | undefined
  ): Record<string, GeneratorOutput> {
    if (generatorType) return (getKnowledgeItem(generatorType) as GeneratorType).outputs;
    const propTypeDef = getKnowledgeItem(propertyType) as PropertyType;
    // From here, idenetical to parserParams
    if (propTypeDef.value_type === COMPOSITE_PROPERTY_VALUE_TYPE) {
      return fromPairs(
        Object.entries(propTypeDef.components || {}).map(([componentKey, component]) => [
          componentKey,
          { ...pick(component, ["value_type", "label"]), required: false },
        ])
      );
    } else {
      return {
        value: {
          value_type: propTypeDef.value_type as GraphValueType,
          label: "Value",
        },
      };
    }
  }

  function isAncestorOf(subject: string, candidateAncestorId: string): boolean {
    let current: KnowledgeItem | null = getKnowledgeItem(subject);
    while (current != null) {
      if (knowledgeItemId(current) === candidateAncestorId) return true;
      if (current.parent != null) {
        current = getKnowledgeItem(current.parent);
      } else {
        current = null;
      }
    }
    return false;
  }

  function isRecordConceptType(type: string): boolean {
    return type === RECORD_CONCEPT_TYPE || isAncestorOf(type, RECORD_CONCEPT_TYPE);
  }

  return {
    typeLookup,
    typeLabel,
    typeLabelOrDefault,
    propertyComponentLabel,
    propertyAndComponentLabel,
    conceptTitle,
    getKnowledgeItem,
    getKnowledgeItemsOfType,
    validParsers,
    isAncestorOf,
    isLocalType,
    parserParams,
    typeLabelWithAdHocParent,
    isRecordConceptType,
    validGenerators,
    generatorOutputs,
    getConceptIconName,
  };
}
