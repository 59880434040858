/**
 * Modifier keys.
 *
 * mod = platform default modifier, meta on Mac/iOS, control otherwise.
 */
export type ModifierKey = "alt" | "shift" | "control" | "meta";
export type ModifierKeyWithMod = ModifierKey | "mod";

/**
 * Key with optional modifier keys.
 */
export class KeyCommand {
  public modifiers: ModifierKey[];

  constructor(
    public key: string,
    modifiers?: ModifierKeyWithMod[]
  ) {
    this.modifiers = (modifiers || []).map(convertKey);
  }

  public format(): string {
    const mode = getOperatingSystem();
    if (mode === "apple") {
      return this.formatApple();
    }
    return this.formatNonApple(mode);
  }

  public formatApple(): string {
    const modifierKeys = this.modifiers;
    let keys = "";
    if (modifierKeys.includes("control")) {
      keys += "⌃";
    }
    if (modifierKeys.includes("alt")) {
      keys += "⌥";
    }
    if (modifierKeys.includes("shift")) {
      keys += "⇧";
    }
    if (modifierKeys.includes("meta")) {
      keys += "⌘";
    }
    return keys + this.formatCode(this.key);
  }

  public formatNonApple(mode: "windows" | "linux"): string {
    const modifierKeys = this.modifiers;
    const keys = [];
    if (modifierKeys.includes("control")) {
      keys.push("Ctrl");
    }
    if (modifierKeys.includes("alt")) {
      keys.push("Alt");
    }
    if (modifierKeys.includes("shift")) {
      keys.push("Shift");
    }
    if (modifierKeys.includes("meta")) {
      if (mode === "windows") {
        keys.push("❖");
      } else {
        keys.push("Super");
      }
    }
    keys.push(this.formatCode(this.key));
    return keys.join("+");
  }

  public static readKeyCommand(keyString: string): KeyCommand {
    const parts = keyString.split("-");
    const modifiers = parts.slice(0, -1) as unknown as ModifierKeyWithMod[];
    const key = parts[parts.length - 1];
    return new KeyCommand(key, modifiers);
  }

  private formatCode(code: string) {
    if (code.startsWith("Key")) {
      return code.substring(3);
    }
    switch (code) {
      case "Backslash":
        return "\\";
    }
    return code;
  }
}

function isApple(): boolean {
  return navigator.userAgent.match(/Macintosh|iPhone|iPad|iPod/i) !== null;
}

function isWindows(): boolean {
  return navigator.userAgent.match(/Windows/i) !== null;
}

export function getOperatingSystem(): "apple" | "windows" | "linux" {
  if (isApple()) {
    return "apple";
  } else if (isWindows()) {
    return "windows";
  }
  return "linux";
}

function convertKey(key: ModifierKeyWithMod): ModifierKey {
  if (key !== "mod") {
    return key;
  }
  return getOperatingSystem() === "apple" ? "meta" : "control";
}
