import { httpClient as axios } from "@/common/http/http";
import {
  Async,
  asyncFailed,
  asyncHasValue,
  asyncInProgress,
  asyncSucceeded,
  asyncValue,
} from "@/common/lib/async";
import { convertRequestFormat } from "@/common/lib/derivedV2";
import { GraphConcept, invertLinkDescriptor, LinkDescriptor } from "@/common/lib/graph";
import { BASE_CONCEPT_TYPE, PropertyKnowledgeRef } from "@/common/lib/knowledge";
import { CTMap } from "@/common/lib/map";
import {
  FetchNConcept,
  FetchNPropertySet,
  FetchNRequest,
  FetchNResponse,
  NON_NEIGHBORHOOD_PATH_KEYS,
  PropertyFilter,
  RootAndNeighborRefs,
} from "@/common/lib/query";
import { AxiosResponse } from "axios";
import { flattenDeep, last, merge } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { computed, Ref, ref, watchEffect } from "vue";

export default function useQuery(module: string, query: () => FetchNRequest, map?: () => CTMap) {
  const queryResults: Ref<Async<FetchNResponse>> = ref(asyncInProgress());
  let latestQueryId = ""; // Guards against query races by ensuring only the latest result gets loaded

  watchEffect(async function () {
    const ourQueryId = uuidv4();
    latestQueryId = ourQueryId;
    queryResults.value = asyncInProgress();
    const request = { ...query(), map: map?.() };
    const requestV2 = convertRequestFormat(request);
    let response: AxiosResponse<FetchNResponse>;
    try {
      response = await axios.post(`/api/projects/${module}/query`, requestV2);
    } catch (error) {
      queryResults.value = asyncFailed("Couldn't load data.");
      return;
    }
    if (latestQueryId === ourQueryId) queryResults.value = asyncSucceeded(response.data);
  });

  function rootConcept() {
    if (!asyncHasValue(queryResults.value)) return emptyConcept();
    const results = asyncValue(queryResults.value)!;
    const rootId = results.paths[0].root_id;
    return dataToConcept(results.data[rootId]);
  }

  const neighborhoods = computed(function () {
    if (!asyncHasValue(queryResults.value)) return [];
    const results = asyncValue(queryResults.value)!;
    return Object.entries(query().neighbors ?? {})
      .map(function ([neighKey, neighDef]) {
        const dataKeys = (results.paths[0][neighKey] || []).map(last);
        return {
          key: neighKey,
          concepts: dataKeys.map((dk) => dataToConcept(results.data[dk!])),
          pivotPath: [invertLinkDescriptor(neighDef[0] as LinkDescriptor), query().concept_type],
          pivotFilters: query().filters as PropertyFilter[],
        };
      })
      .filter((neigh) => neigh.concepts.length > 0);
  });

  const resultTable = computed(function () {
    const results = asyncValue(queryResults.value)!;
    return results.paths.map(function (refs: RootAndNeighborRefs) {
      const conceptIds: string[] = [refs.root_id];
      for (const [refKey, ref] of Object.entries(refs)) {
        if (!NON_NEIGHBORHOOD_PATH_KEYS.includes(refKey)) {
          conceptIds.push(...flattenDeep(ref as string[][]).filter((_, i) => i % 2));
        }
      }
      const neighborConcepts = conceptIds.map((ref) => results.data[ref]);
      const propertySets = neighborConcepts.map((concept) => concept.properties);
      return merge({}, ...propertySets) as FetchNPropertySet;
    });
  });

  const isDone = computed(() => asyncHasValue(queryResults.value));

  const isEmpty = computed(() => asyncValue(queryResults.value)!.paths.length == 0);

  function dataToConcept(data: FetchNConcept): GraphConcept {
    return {
      id: "",
      type: data.concept_type,
      properties: Object.entries(data.properties).flatMap(function ([type, values]) {
        return values.map((value) => ({
          id: "",
          type: type as PropertyKnowledgeRef,
          value,
        }));
      }),
    };
  }

  function emptyConcept(): GraphConcept {
    return { id: "", type: BASE_CONCEPT_TYPE, properties: [] };
  }

  return {
    queryResults,
    resultTable,
    rootConcept,
    neighborhoods,
    isDone,
    isEmpty,
  };
}
