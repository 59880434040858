import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex shrink-0 grow basis-0 flex-col items-start gap-5 p-10" }
const _hoisted_2 = { class: "self-stretch text-lg font-bold text-gray-200" }
const _hoisted_3 = { class: "text-base text-gray-200" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex items-center justify-between border-b-[3px] border-orange bg-gray-900 pr-10", {
      'border-red-500 bg-red-900': $props.mode === 'error',
      'border-yellow-500 bg-yellow-900': $props.mode === 'warning',
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "title")
      ]),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "body")
      ])
    ]),
    _renderSlot(_ctx.$slots, "actions")
  ], 2 /* CLASS */))
}