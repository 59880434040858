<template>
  <div class="pb-15">
    <div class="pb-10 text-lg">{{ visualization.title }}</div>
    <div class="loading" v-if="queryResults.status === AsyncStatus.InProgress">
      <Spinner />
    </div>
    <div v-if="queryResults.status === AsyncStatus.Failed">{{ queryResults.message }}</div>
    <component
      :is="vizComponent"
      :visualization="visualization"
      :results="resultTable"
      v-if="queryResults.status === AsyncStatus.Succeeded"
    />
  </div>
</template>

<script setup lang="ts">
import { Visualization } from "@/reader/lib/visualization";
import { computed, toRefs } from "vue";
import useQuery from "@/reader/composables/useQuery";
import { FetchNRequest } from "@/common/lib/query";
import { AsyncStatus } from "@/common/lib/async";
import Spinner from "@/common/components/Spinner.vue";
import { useExploreStore } from "@/reader/stores/explore";

const exploreStore = useExploreStore();

const props = defineProps<{ visualization: Visualization }>();
const { visualization } = toRefs(props);

const vizComponent = computed(() => `visualization-${visualization.value.type}`);

const { resultTable, queryResults } = useQuery(
  exploreStore.module!,
  () => visualization.value.query as FetchNRequest,
  () => exploreStore.map!
);
</script>
