import { Tab } from "@/common/components/tab";
import { environment } from "@/common/environments/environmentLoader";
import { defineStore } from "pinia";

export type SelectedTab = "user-settings" | "connection";
export type SubPage = "modules" | "user-settings";

export enum Routes {
  Home = "home",
  Connections = "connections",
  UserSettings = "user-settings",
}

interface State {
  selectedTab: SelectedTab;
  userSelected: boolean;
  availableTabs: Tab[];
}

const canEditDatabricksConnection = environment.requireBoolean("EDIT_DATABRICKS_CONNECTION");

const availableTabs = [{ label: "User settings", key: "user-settings" }];
if (canEditDatabricksConnection) {
  availableTabs.push({ label: "Connection", key: "connection" });
}

export const useSettingsStore = defineStore("settings", {
  state: (): State => ({
    selectedTab: "user-settings",
    userSelected: false,
    availableTabs: availableTabs,
  }),
  getters: {
    subPage: (state) => subPage(state.userSelected),
  },
  actions: {
    openConnectionPage() {
      if (!canEditDatabricksConnection) {
        return;
      }
      this.userSelected = true;
      this.selectedTab = "connection";
    },
    openUserSettings() {
      this.userSelected = true;
      this.selectedTab = "user-settings";
    },
    openHome() {
      this.userSelected = false;
    },
  },
});

function subPage(userSelected: boolean): SubPage {
  if (userSelected) {
    return "user-settings";
  }
  return "modules";
}
