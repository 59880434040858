<template>
  <div class="absolute inset-0 flex flex-col">
    <div
      v-if="exploreStore.context === ExploreContext.Embedded"
      class="border-b border-b-gray-900 bg-gray-800 px-20 py-10"
    >
      <AskBar />
    </div>
    <Teleport v-if="exploreStore.context === ExploreContext.Standalone" to="#title-bar-controls">
      <AskBar />
    </Teleport>
    <div class="flex min-h-0 basis-full flex-row">
      <div
        v-if="exploreStore.showSidebars()"
        class="relative flex-none basis-[325px] overflow-y-auto border-r border-gray-200 bg-gray-100 p-15 dark:border-r-gray-900 dark:bg-gray-800"
      >
        <QueryBasics />
      </div>
      <div class="relative isolate flex basis-full flex-col">
        <div
          class="absolute inset-0 z-10 bg-gray-200 dark:bg-gray-900"
          v-show="exploreMetagraphStore.visible"
        >
          <MetagraphPane />
        </div>
        <div class="absolute inset-0 z-0 flex flex-col">
          <div
            class="flex flex-none flex-row justify-between border-b border-b-gray-200 bg-gray-100 dark:border-b-gray-900 dark:bg-gray-800"
          >
            <div class="flex flex-row">
              <TabSelect
                :tabs="tabs"
                :modelValue="exploreStore.mode"
                @update:model-value="exploreStore.setMode"
              />
            </div>
            <div class="flex items-center justify-between gap-10 pr-5">
              <TextButton
                label="Create test"
                icon="md:labs"
                v-if="createTestEnabled"
                @click="showCreateTestDialog = true"
              />
              <IconButton
                class="rotate-90"
                :name="exploreStore.toolsVisible ? 'reveal-panel' : 'hide-panel'"
                @click="exploreStore.toolsVisible = !exploreStore.toolsVisible"
              />
            </div>
          </div>
          <div class="relative basis-full bg-white dark:bg-gray-900">
            <AsyncWrapper :async="exploreStore.table" v-if="exploreStore.mode === Mode.Table">
              <Table />
            </AsyncWrapper>
            <AsyncWrapper :async="exploreStore.sqlData" v-if="exploreStore.mode === Mode.SQL">
              <SqlView />
            </AsyncWrapper>
          </div>
          <QueryProblems />
        </div>
      </div>
      <div
        class="relative flex-none basis-[400px] border-l border-gray-300 dark:border-gray-900"
        v-if="exploreStore.toolsVisible"
      >
        <ExploreTools />
      </div>
    </div>
  </div>

  <Modal v-if="exploreStore.creatingCalculation !== undefined">
    <CalculationForm />
  </Modal>

  <Dialog
    v-if="showCreateTestDialog"
    title="Create Query Engine Test"
    success-label="Create Test"
    @succeeded="createExplorerTest()"
    @cancelled="showCreateTestDialog = false"
  >
    <Textbox label="Name of integration test" v-model="testName" />
  </Dialog>
</template>

<script lang="ts" setup>
import AsyncWrapper from "@/common/components/AsyncWrapper.vue";
import Modal from "@/common/components/Modal.vue";
import CalculationForm from "../components/explore/calculation/CalculationForm.vue";
import MetagraphPane from "../components/explore/metagraph/MetagraphPane.vue";
import QueryBasics from "../components/explore/QueryBasics.vue";
import QueryProblems from "../components/explore/QueryProblems.vue";
import SqlView from "../components/explore/SqlView.vue";
import Table from "../components/explore/Table.vue";
import { Mode, useExploreStore, ExploreContext } from "../stores/explore";
import { useExploreMetagraphStore } from "../stores/exploreMetagraph";
import { computed, onMounted, ref } from "vue";
import TabSelect from "@/common/components/TabSelect.vue";
import ExploreTools from "../components/explore/ExploreTools.vue";
import IconButton from "@/common/components/IconButton.vue";
import AskBar from "../components/explore/AskBar.vue";
import TextButton from "@/common/components/TextButton.vue";
import { environment } from "@/common/environments/environmentLoader";
import Textbox from "@/common/components/Textbox.vue";
import Dialog from "@/common/components/Dialog.vue";
import { useRoute } from "vue-router";
import { isString } from "lodash";

const exploreStore = useExploreStore();
const exploreMetagraphStore = useExploreMetagraphStore();

const createTestEnabled = environment.requireBoolean("EXPLORER_CREATE_TEST_ENABLED");
const showCreateTestDialog = ref(false);
const testName = ref("");

const tabs = computed(() => {
  return [
    {
      label: "Table",
      key: Mode.Table,
      icon: "md:table",
    },
    {
      label: "SQL",
      key: Mode.SQL,
      icon: "SQL",
    },
  ];
});

function createExplorerTest() {
  exploreStore.load(true, { testName: testName.value });
  testName.value = "";
  showCreateTestDialog.value = false;
}

onMounted(function () {
  if (exploreStore.context === ExploreContext.Standalone) {
    const route = useRoute();
    if (isString(route.query.bookmark)) {
      const bookmark = JSON.parse(route.query.bookmark);
      exploreStore.loadBookmark(bookmark);
    }
  }
});
</script>
