import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "relative mb-10" }
const _hoisted_2 = {
  key: 0,
  class: "absolute left-[-21px] flex h-[26px] items-center bg-gray-100 dark:bg-gray-800"
}
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "ml-5 basis-full" }
const _hoisted_5 = {
  key: 0,
  class: "role-summary"
}
const _hoisted_6 = { class: "font-bold" }
const _hoisted_7 = {
  key: 1,
  class: "ml-[12px] mt-5 border-l border-l-gray-200 pl-10 dark:border-l-gray-700"
}
const _hoisted_8 = {
  key: 0,
  class: "mt-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TreeConcept = _resolveComponent("TreeConcept", true)!
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$setup.isRoot)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode($setup["Disclosure"], {
            expanded: $setup.isExpanded,
            onToggle: $setup.toggleExpanded
          }, null, 8 /* PROPS */, ["expanded"])
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "rounded-full p-[3px]",
        style: _normalizeStyle({ backgroundColor: $setup.color })
      }, [
        _createVNode($setup["Icon"], {
          class: "icon",
          name: $setup.iconName,
          size: "l",
          color: "gray2"
        }, null, 8 /* PROPS */, ["name"])
      ], 4 /* STYLE */),
      _createVNode($setup["Dropdown"], {
        placement: "right",
        class: "mr-10 flex basis-full cursor-pointer items-center"
      }, {
        popper: _withCtx(() => [
          _withDirectives(_createVNode($setup["Menu"], { items: $setup.menuItems }, null, 8 /* PROPS */, ["items"]), [
            [_directive_close_popper]
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            ($setup.linkDescription)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString($setup.linkDescription) + " ", 1 /* TEXT */))
              : _createCommentVNode("v-if", true),
            _createElementVNode("span", _hoisted_6, _toDisplayString($setup.name), 1 /* TEXT */)
          ]),
          _createVNode($setup["IconButton"], {
            name: "chevron-down",
            size: "xs"
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    ($setup.isExpanded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.derivedColumns[$setup.TOP_LEVEL_DERIVED_COLS], (alias) => {
            return (_openBlock(), _createBlock($setup["TreeDerivedColumn"], {
              alias: alias,
              key: alias
            }, null, 8 /* PROPS */, ["alias"]))
          }), 128 /* KEYED_FRAGMENT */)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.propertyTypes, (propertyType) => {
            return (_openBlock(), _createBlock($setup["TreeProperty"], {
              "property-type": propertyType,
              "derived-aliases": $setup.derivedColumns[propertyType] || [],
              "concept-path": $setup.path,
              key: propertyType
            }, null, 8 /* PROPS */, ["property-type", "derived-aliases", "concept-path"]))
          }), 128 /* KEYED_FRAGMENT */)),
          ($setup.children.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.children, (child) => {
                  return (_openBlock(), _createBlock(_component_TreeConcept, {
                    path: child.path,
                    key: child.key
                  }, null, 8 /* PROPS */, ["path"]))
                }), 128 /* KEYED_FRAGMENT */))
              ]))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}